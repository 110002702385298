import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireAdmin, RequireAuth, RequireManager, RequireSuperAdmin } from 'helpers/Auth/Auth';
import Preloader from 'ui/Preloader/Preloader';
import Layout from 'components/Layout/Layout';
import StartTour from 'components/StartTour/StartTour';
import ManagerLayout from 'components/ManagerLayout/ManagerLayout';
import AdminLayout from 'components/AdminLayout/AdminLayout';
import NotFoundPage from './NotFoundPage';
import UnderConstructionPage from './UnderConstructionPage';
import LoginPage from './LoginPage';

type VERIFY_KEY = string | null;
type CODE = string | null;

const ResetPasswordPage = React.lazy(() => import('./ResetPasswordPage'));
const AdminDashboard = React.lazy(() => import('./AdminDashboardPage'));
const ManagersListPage = React.lazy(() => import('./ManagersListPage'));
const TransactionsPage = React.lazy(() => import('./TransactionsPage'));
const DepositPage = React.lazy(() => import('./DepositPage'));
const WithdrawalPage = React.lazy(() => import('./WithdrawalPage'));
const DashboardPage = React.lazy(() => import('./DashboardPage'));
const AdminTransactionsPage = React.lazy(() => import('./AdminTransactionsPage'));
const AdminListPage = React.lazy(() => import('./AdminListPage'));
const LogsPage = React.lazy(() => import('./LogsPage'));
const OfficesPage = React.lazy(() => import('./OfficesPage'));

export const URLS_MAP = {
    root: '/',
    login: '/login',
    signup: '/signup',
    recover: '/recover',
    dashboard: '/dashboard',
    transactions: '/transactions',
    list: '/list',
    offices: '/offices',
    logs: '/logs',
    resetPassword: (verifyKey: VERIFY_KEY = ':verifyKey', code: CODE = ':code') =>
        `/reset-password/${verifyKey}/${code}`,
    manager: {
        index: '/manager',
        deposit: '/manager/deposit',
        withdrawal: '/manager/withdrawal'
    },
    admin: {
        index: '/admin/dashboard',
        dashboard: '/admin/dashboard',
        transactions: '/admin/transactions',
        managers: '/admin/managers',
        logs: '/admin/logs'
    },
    notFound: '*'
};

function LoadProgress() {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0
            }}
        >
            <Preloader variant="linear" />
        </div>
    );
}

export default function MainRoutes() {
    return (
        <Routes>
            <Route path={URLS_MAP.login} element={<LoginPage />} />
            <Route
                path={URLS_MAP.resetPassword()}
                element={
                    <React.Suspense fallback={<LoadProgress />}>
                        <ResetPasswordPage />
                    </React.Suspense>
                }
            />
            <Route element={<RequireAuth />}>
                <Route path={URLS_MAP.root} element={<StartTour />} />
                <Route element={<RequireAdmin />}>
                    <Route element={<AdminLayout />}>
                        <Route
                            path={URLS_MAP.admin.dashboard}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <AdminDashboard />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.admin.managers}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <ManagersListPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.admin.transactions}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <AdminTransactionsPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.admin.logs}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <LogsPage />
                                </React.Suspense>
                            }
                        />
                    </Route>
                </Route>
                <Route element={<RequireManager />}>
                    <Route element={<ManagerLayout />}>
                        <Route
                            path={URLS_MAP.manager.index}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <TransactionsPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.manager.deposit}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <DepositPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.manager.withdrawal}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <WithdrawalPage />
                                </React.Suspense>
                            }
                        />
                        <Route path="*" element={<UnderConstructionPage />} />
                    </Route>
                </Route>
                <Route element={<RequireSuperAdmin />}>
                    <Route element={<Layout />}>
                        <Route
                            path={URLS_MAP.dashboard}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <DashboardPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.transactions}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <AdminTransactionsPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.list}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <AdminListPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.logs}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <LogsPage />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path={URLS_MAP.offices}
                            element={
                                <React.Suspense fallback={<LoadProgress />}>
                                    <OfficesPage />
                                </React.Suspense>
                            }
                        />
                        <Route path="*" element={<UnderConstructionPage />} />
                    </Route>
                </Route>
            </Route>
            <Route path={URLS_MAP.notFound} element={<NotFoundPage />} />
        </Routes>
    );
}
