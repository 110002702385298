import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import { URLS_MAP } from 'routes/routes';
import { useMeQuery } from 'apollo/generated';
import Preloader from 'ui/Preloader/Preloader';
import ManagerHeader from '../ManagerHeader/ManagerHeader';
import styles from './ManagerLayout.module.scss';

export default function ManagerLayout() {
    const { pathname } = useLocation();
    const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);
    const { data, loading } = useMeQuery({ fetchPolicy: 'network-only' });

    const { me } = data ?? {};
    const { office, email } = me ?? {};
    const { city, address } = office ?? {};

    const fullAddress = `${city ? `г. ${city}` : ''}${address ? `, ${address}` : ''}`;
    const isIndexPage = pathname === URLS_MAP.manager.index;

    if (loading || (data && !me)) {
        return (
            <div className={styles.Preloader}>
                <Preloader />
            </div>
        );
    }

    return (
        <div className={isIndexPage && !isTabletOrLess ? styles.Root : ''}>
            <ManagerHeader
                address={fullAddress}
                email={email}
                isLimitedView={!isIndexPage}
                isPayment={!isIndexPage}
                LogoProps={{ disabled: isIndexPage }}
            />
            <main className={isIndexPage && !isTabletOrLess ? styles.Main : ''}>
                <Outlet />
            </main>
        </div>
    );
}
