import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    DateTime: { input: any; output: any };
};

export type AcceptCashInput = {
    exchangeId: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type AcceptCashOutput =
    | AcceptCashSuccess
    | ExchangeNotFoundError
    | InvalidCredentialsError
    | WrongExchangeStatusError;

export type AcceptCashSuccess = {
    __typename: 'AcceptCashSuccess';
    exchange: ExchangeNode;
};

export enum ActionType {
    AcceptCash = 'ACCEPT_CASH',
    ChangePassword = 'CHANGE_PASSWORD',
    ConfirmResetPassword = 'CONFIRM_RESET_PASSWORD',
    CreateOffice = 'CREATE_OFFICE',
    CreateUser = 'CREATE_USER',
    DeleteOffice = 'DELETE_OFFICE',
    DeleteUser = 'DELETE_USER',
    DownloadReport = 'DOWNLOAD_REPORT',
    ResetPassword = 'RESET_PASSWORD',
    SetFee = 'SET_FEE',
    SignIn = 'SIGN_IN',
    UpdateOffice = 'UPDATE_OFFICE',
    UpdateUser = 'UPDATE_USER'
}

export type AskForWalletInput = {
    exchangePair: Scalars['String']['input'];
    inputAmount?: InputMaybe<Scalars['String']['input']>;
    outputAmount?: InputMaybe<Scalars['String']['input']>;
    walletName: WalletName;
};

export type AskForWalletSuccess = {
    __typename: 'AskForWalletSuccess';
    calc?: Maybe<ExchangeCalcNode>;
    deepLink?: Maybe<Scalars['String']['output']>;
    qrData?: Maybe<Scalars['String']['output']>;
    verificationCode: Scalars['String']['output'];
};

export type CalculationOutput =
    | CurrencyNotFoundError
    | ExchangeAmountTooHighError
    | ExchangeAmountTooLowError
    | ExchangeCalcNode
    | ExchangePairNotFoundError
    | ExchangePairNotPresentError
    | InvalidParamsError
    | InvalidTransferParamsError
    | UnderMaintenanceError;

export type CashierFilterInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
    officeId?: InputMaybe<Scalars['Int']['input']>;
    search?: InputMaybe<Scalars['String']['input']>;
};

export type ChangePasswordInput = {
    newPassword: Scalars['String']['input'];
    oldPassword: Scalars['String']['input'];
};

export type ChangePasswordOutput = ChangePasswordSuccess | InvalidCredentialsError | InvalidPasswordError;

export type ChangePasswordSuccess = {
    __typename: 'ChangePasswordSuccess';
    me: UserNode;
};

export type CheckPasswordInput = {
    password: Scalars['String']['input'];
};

export type CheckPasswordOutput = CheckPasswordSuccess | InvalidCredentialsError;

export type CheckPasswordSuccess = {
    __typename: 'CheckPasswordSuccess';
    me: UserNode;
};

export type ConfirmPasswordResetInput = {
    password: Scalars['String']['input'];
};

export type ConfirmPasswordResetOutput =
    | ConfirmPasswordResetSuccess
    | InvalidJwtError
    | InvalidPasswordError
    | InvalidVerifyCodeError
    | UserNotFoundError;

export type ConfirmPasswordResetSuccess = {
    __typename: 'ConfirmPasswordResetSuccess';
    me?: Maybe<UserNode>;
    token?: Maybe<Scalars['String']['output']>;
};

export type ConfirmationFailedError = {
    __typename: 'ConfirmationFailedError';
    errorMessage: Scalars['String']['output'];
};

export type ConnectByWalletOutput = AskForWalletSuccess | DeeplinkNotGeneratedError | InvalidParamsError;

export type CreateOfficeInput = {
    address: Scalars['String']['input'];
    administratorId?: InputMaybe<Scalars['String']['input']>;
    city: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type CreateOfficeOutput =
    | CreateOfficeSuccess
    | InvalidParamsError
    | OfficeNotFoundError
    | UserIsInOtherOfficeError
    | UserNotFoundError;

export type CreateOfficeSuccess = {
    __typename: 'CreateOfficeSuccess';
    office: OfficeNode;
};

export type CreateUserInput = {
    email: Scalars['String']['input'];
    officeId?: InputMaybe<Scalars['Int']['input']>;
    password: Scalars['String']['input'];
    role: UserRole;
};

export type CreateUserOutput =
    | CreateUserSuccess
    | InvalidCredentialsError
    | InvalidEmailError
    | InvalidParamsError
    | OfficeNotFoundError
    | UserAlreadyExistsError;

export type CreateUserSuccess = {
    __typename: 'CreateUserSuccess';
    user: UserNode;
};

export type CurrencyNode = {
    __typename: 'CurrencyNode';
    blockchain?: Maybe<Scalars['String']['output']>;
    feeFix?: Maybe<Scalars['String']['output']>;
    feePercentage?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    minTransferAmount?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    precision?: Maybe<Scalars['String']['output']>;
    withdrawFeeFix?: Maybe<Scalars['String']['output']>;
    withdrawFeePercentage?: Maybe<Scalars['String']['output']>;
};

export type CurrencyNotFoundError = {
    __typename: 'CurrencyNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type DeeplinkNotGeneratedError = {
    __typename: 'DeeplinkNotGeneratedError';
    errorMessage: Scalars['String']['output'];
};

export type DeleteOfficeOutput = DeleteOfficeSuccess | OfficeNotFoundError;

export type DeleteOfficeSuccess = {
    __typename: 'DeleteOfficeSuccess';
    officeId: Scalars['String']['output'];
};

export type DeleteUserOutput = DeleteUserSuccess | UserNotFoundError;

export type DeleteUserSuccess = {
    __typename: 'DeleteUserSuccess';
    userId: Scalars['String']['output'];
};

export type EosAccountAlreadyTakenError = {
    __typename: 'EosAccountAlreadyTakenError';
    errorMessage: Scalars['String']['output'];
};

export type ExchangeAmountTooHighError = {
    __typename: 'ExchangeAmountTooHighError';
    errorMessage: Scalars['String']['output'];
};

export type ExchangeAmountTooLowError = {
    __typename: 'ExchangeAmountTooLowError';
    errorMessage: Scalars['String']['output'];
};

export type ExchangeCalcInput = {
    exchangePair: Scalars['String']['input'];
    inputAmount?: InputMaybe<Scalars['String']['input']>;
    outputAmount?: InputMaybe<Scalars['String']['input']>;
};

export type ExchangeCalcNode = {
    __typename: 'ExchangeCalcNode';
    amountToPay?: Maybe<Scalars['String']['output']>;
    amountToReceive?: Maybe<Scalars['String']['output']>;
    cryptoAmount?: Maybe<Scalars['String']['output']>;
    exchangeRate?: Maybe<Scalars['String']['output']>;
    inputFee?: Maybe<Scalars['String']['output']>;
    outputFee?: Maybe<Scalars['String']['output']>;
};

export type ExchangeFiatStatsNode = {
    __typename: 'ExchangeFiatStatsNode';
    officeId?: Maybe<Scalars['Int']['output']>;
    stats?: Maybe<Array<Maybe<FiatCurrencyStatsNode>>>;
};

export type ExchangeFilterInput = {
    createdAfter?: InputMaybe<Scalars['String']['input']>;
    createdBefore?: InputMaybe<Scalars['String']['input']>;
    currencyId?: InputMaybe<Scalars['String']['input']>;
    currencyName?: InputMaybe<Scalars['String']['input']>;
    eosAccount?: InputMaybe<Scalars['String']['input']>;
    exchangePairId?: InputMaybe<Scalars['String']['input']>;
    fiatName?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    inputAmountGe?: InputMaybe<Scalars['String']['input']>;
    inputAmountLe?: InputMaybe<Scalars['String']['input']>;
    officeId?: InputMaybe<Scalars['Int']['input']>;
    outputAmountGe?: InputMaybe<Scalars['String']['input']>;
    outputAmountLe?: InputMaybe<Scalars['String']['input']>;
    paidAfter?: InputMaybe<Scalars['String']['input']>;
    paidBefore?: InputMaybe<Scalars['String']['input']>;
    pc4storeId?: InputMaybe<Scalars['String']['input']>;
    pc4storeStatus?: InputMaybe<Scalars['String']['input']>;
    search?: InputMaybe<Scalars['String']['input']>;
    sequentNumber?: InputMaybe<Scalars['Int']['input']>;
    status?: InputMaybe<Array<InputMaybe<ExchangeStatusEnum>>>;
    txid?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<ExchangeTypeEnum>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type ExchangeList = {
    __typename: 'ExchangeList';
    exchanges: Array<Maybe<ExchangeNode>>;
    pageInfo: PageInfo;
};

export type ExchangeNode = {
    __typename: 'ExchangeNode';
    created: Scalars['DateTime']['output'];
    eosAccount?: Maybe<Scalars['String']['output']>;
    exchangePairId: Scalars['String']['output'];
    id?: Maybe<Scalars['String']['output']>;
    inputAmount: Scalars['String']['output'];
    inputCurrency: Scalars['String']['output'];
    office?: Maybe<LogOfficeNode>;
    officeId: Scalars['Int']['output'];
    outputAmount: Scalars['String']['output'];
    outputCurrency: Scalars['String']['output'];
    paid?: Maybe<Scalars['DateTime']['output']>;
    paymentParams?: Maybe<PaymentParamsNode>;
    pc4storeId?: Maybe<Scalars['String']['output']>;
    refund?: Maybe<RefundNode>;
    sequentNumber?: Maybe<Scalars['Int']['output']>;
    status: ExchangeStatusEnum;
    txid?: Maybe<Scalars['String']['output']>;
    type: ExchangeTypeEnum;
    updated: Scalars['DateTime']['output'];
    user?: Maybe<LogUserNode>;
    userId: Scalars['String']['output'];
};

export type ExchangeNotFoundError = {
    __typename: 'ExchangeNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type ExchangePairFilterInput = {
    currencyId?: InputMaybe<Scalars['String']['input']>;
    currencyName?: InputMaybe<Scalars['String']['input']>;
    fiatName?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<ExchangeTypeEnum>;
};

export type ExchangePairNode = {
    __typename: 'ExchangePairNode';
    currency?: Maybe<CurrencyNode>;
    exchangeRate?: Maybe<Scalars['String']['output']>;
    fiatName: Scalars['String']['output'];
    id?: Maybe<Scalars['String']['output']>;
    inputPrecision?: Maybe<Scalars['Int']['output']>;
    isAvailable?: Maybe<Scalars['Boolean']['output']>;
    isEnabled?: Maybe<Scalars['Boolean']['output']>;
    outputPrecision?: Maybe<Scalars['Int']['output']>;
    settings: ExchangePairSettingsNode;
    type: ExchangeTypeEnum;
};

export type ExchangePairNodeSettingsArgs = {
    merged?: InputMaybe<Scalars['Boolean']['input']>;
    officeId?: InputMaybe<Scalars['Int']['input']>;
};

export type ExchangePairNotFoundError = {
    __typename: 'ExchangePairNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type ExchangePairNotPresentError = {
    __typename: 'ExchangePairNotPresentError';
    errorMessage: Scalars['String']['output'];
};

export type ExchangePairSettingsNode = {
    __typename: 'ExchangePairSettingsNode';
    feeFix?: Maybe<Scalars['String']['output']>;
    feePercentage?: Maybe<Scalars['String']['output']>;
    limit7d?: Maybe<Scalars['String']['output']>;
    limit24h?: Maybe<Scalars['String']['output']>;
    limit30d?: Maybe<Scalars['String']['output']>;
    maximalAmount?: Maybe<Scalars['String']['output']>;
    minimalAmount?: Maybe<Scalars['String']['output']>;
};

export enum ExchangeStatusEnum {
    Cancelled = 'CANCELLED',
    Created = 'CREATED',
    Expired = 'EXPIRED',
    Failed = 'FAILED',
    MoneyReceived = 'MONEY_RECEIVED',
    Paid = 'PAID',
    Refunded = 'REFUNDED',
    Sending = 'SENDING',
    Sent = 'SENT',
    Success = 'SUCCESS'
}

export enum ExchangeTypeEnum {
    CryptoFiat = 'CRYPTO_FIAT',
    FiatCrypto = 'FIAT_CRYPTO'
}

export type FiatCurrencyStatsNode = {
    __typename: 'FiatCurrencyStatsNode';
    day: PeriodStatisticsNode;
    fiatName: Scalars['String']['output'];
    month: PeriodStatisticsNode;
    week: PeriodStatisticsNode;
};

export type InvalidCredentialsError = {
    __typename: 'InvalidCredentialsError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidEmailError = {
    __typename: 'InvalidEmailError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidJwtError = {
    __typename: 'InvalidJWTError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidParamsError = {
    __typename: 'InvalidParamsError';
    errorMessage: Scalars['String']['output'];
    paramName?: Maybe<Scalars['String']['output']>;
};

export type InvalidPasswordError = {
    __typename: 'InvalidPasswordError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidTransferParamsError = {
    __typename: 'InvalidTransferParamsError';
    errorMessage: Scalars['String']['output'];
};

export type InvalidVerifyCodeError = {
    __typename: 'InvalidVerifyCodeError';
    errorMessage: Scalars['String']['output'];
    hasResendAttempts: Scalars['Boolean']['output'];
};

export type LogOfficeNode = {
    __typename: 'LogOfficeNode';
    address?: Maybe<Scalars['String']['output']>;
    city?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    name?: Maybe<Scalars['String']['output']>;
};

export type LogUserNode = {
    __typename: 'LogUserNode';
    email: Scalars['String']['output'];
    id: Scalars['String']['output'];
};

export type LoginIsEmptyError = {
    __typename: 'LoginIsEmptyError';
    errorMessage: Scalars['String']['output'];
};

export type MakeExchangeInput = {
    exchangePair: Scalars['String']['input'];
    inputAmount?: InputMaybe<Scalars['String']['input']>;
    outputAmount?: InputMaybe<Scalars['String']['input']>;
};

export type MakeExchangeOutput =
    | CurrencyNotFoundError
    | ExchangeAmountTooHighError
    | ExchangeAmountTooLowError
    | ExchangePairNotFoundError
    | ExchangePairNotPresentError
    | InvalidParamsError
    | InvalidTransferParamsError
    | MakeExchangeSuccess
    | UnderMaintenanceError;

export type MakeExchangeSuccess = {
    __typename: 'MakeExchangeSuccess';
    exchange: ExchangeNode;
};

export type Mutations = {
    __typename: 'Mutations';
    acceptCash?: Maybe<AcceptCashOutput>;
    askForWallet?: Maybe<ConnectByWalletOutput>;
    changePassword?: Maybe<ChangePasswordOutput>;
    checkPassword?: Maybe<CheckPasswordOutput>;
    confirmPasswordReset?: Maybe<ConfirmPasswordResetOutput>;
    createOffice?: Maybe<CreateOfficeOutput>;
    createUser?: Maybe<CreateUserOutput>;
    deleteOffice?: Maybe<DeleteOfficeOutput>;
    deleteUser?: Maybe<DeleteUserOutput>;
    makeExchange?: Maybe<MakeExchangeOutput>;
    refreshToken?: Maybe<RefreshTokenOutput>;
    reportExchanges?: Maybe<ReportSuccess>;
    resetPassword?: Maybe<ResetPasswordOutput>;
    signin?: Maybe<SigninOutput>;
    updateOffice?: Maybe<UpdateOfficeOutput>;
    updatePairSettings?: Maybe<UpdatePairSettingsOutput>;
    updateReportSettings?: Maybe<UpdateReportSettingsSuccess>;
    updateUser?: Maybe<UpdateUserOutput>;
    verifyCode?: Maybe<VerifyCodeOutput>;
};

export type MutationsAcceptCashArgs = {
    input: AcceptCashInput;
};

export type MutationsAskForWalletArgs = {
    input: AskForWalletInput;
};

export type MutationsChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationsCheckPasswordArgs = {
    input: CheckPasswordInput;
};

export type MutationsConfirmPasswordResetArgs = {
    input: ConfirmPasswordResetInput;
};

export type MutationsCreateOfficeArgs = {
    input: CreateOfficeInput;
};

export type MutationsCreateUserArgs = {
    input: CreateUserInput;
};

export type MutationsDeleteOfficeArgs = {
    officeId: Scalars['Int']['input'];
};

export type MutationsDeleteUserArgs = {
    userId: Scalars['String']['input'];
};

export type MutationsMakeExchangeArgs = {
    input: MakeExchangeInput;
};

export type MutationsReportExchangesArgs = {
    filters: ExchangeFilterInput;
};

export type MutationsResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationsSigninArgs = {
    input: SigninInput;
};

export type MutationsUpdateOfficeArgs = {
    input: UpdateOfficeInput;
};

export type MutationsUpdatePairSettingsArgs = {
    input: PairSettingsInput;
};

export type MutationsUpdateReportSettingsArgs = {
    settings: UpdateReportSettingsInput;
};

export type MutationsUpdateUserArgs = {
    input: UpdateUserInput;
};

export type MutationsVerifyCodeArgs = {
    input: VerifyCodeInput;
};

export type NoConfirmationAttemptsError = {
    __typename: 'NoConfirmationAttemptsError';
    errorMessage: Scalars['String']['output'];
    timeout: Scalars['DateTime']['output'];
};

export type NoResendAttemptsError = {
    __typename: 'NoResendAttemptsError';
    errorMessage: Scalars['String']['output'];
    timeout: Scalars['DateTime']['output'];
};

export type OfficeFilterInput = {
    id?: InputMaybe<Scalars['String']['input']>;
    search?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeList = {
    __typename: 'OfficeList';
    offices: Array<Maybe<OfficeNode>>;
    pageInfo: PageInfo;
};

export type OfficeNode = {
    __typename: 'OfficeNode';
    address?: Maybe<Scalars['String']['output']>;
    administrators?: Maybe<Array<Maybe<UserNode>>>;
    cashiers?: Maybe<Array<Maybe<UserNode>>>;
    city?: Maybe<Scalars['String']['output']>;
    created: Scalars['DateTime']['output'];
    id: Scalars['Int']['output'];
    name?: Maybe<Scalars['String']['output']>;
};

export type OfficeNotFoundError = {
    __typename: 'OfficeNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export enum OfficeSortEnum {
    Address = 'ADDRESS',
    AddressDesc = 'ADDRESS_DESC',
    Created = 'CREATED',
    CreatedDesc = 'CREATED_DESC',
    Name = 'NAME',
    NameDesc = 'NAME_DESC'
}

export type PageInfo = {
    __typename: 'PageInfo';
    total?: Maybe<Scalars['Int']['output']>;
    totalFiltered?: Maybe<Scalars['Int']['output']>;
};

export type PairSettingsInput = {
    exchangePair: Scalars['String']['input'];
    feeFix?: InputMaybe<Scalars['String']['input']>;
    feePercentage?: InputMaybe<Scalars['String']['input']>;
    limit7d?: InputMaybe<Scalars['String']['input']>;
    limit24h?: InputMaybe<Scalars['String']['input']>;
    limit30d?: InputMaybe<Scalars['String']['input']>;
    maximalAmount?: InputMaybe<Scalars['String']['input']>;
    minimalAmount?: InputMaybe<Scalars['String']['input']>;
    officeId?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentParamsNode = {
    __typename: 'PaymentParamsNode';
    amount: Scalars['String']['output'];
    blockchain?: Maybe<Scalars['String']['output']>;
    expirationDate?: Maybe<Scalars['String']['output']>;
    memo?: Maybe<Scalars['String']['output']>;
    metaInfo: Scalars['String']['output'];
    receiver: Scalars['String']['output'];
};

export type PeriodStatisticsNode = {
    __typename: 'PeriodStatisticsNode';
    deposit: Scalars['String']['output'];
    depositCount: Scalars['Int']['output'];
    total: Scalars['String']['output'];
    withdraw: Scalars['String']['output'];
    withdrawCount: Scalars['Int']['output'];
};

export type Query = {
    __typename: 'Query';
    cashiers?: Maybe<UserList>;
    exchange?: Maybe<ExchangeNode>;
    exchangeCalc?: Maybe<CalculationOutput>;
    exchangeFiatStats?: Maybe<ExchangeFiatStatsNode>;
    exchangePairs?: Maybe<Array<Maybe<ExchangePairNode>>>;
    exchanges?: Maybe<ExchangeList>;
    me?: Maybe<UserNode>;
    office?: Maybe<OfficeNode>;
    offices?: Maybe<OfficeList>;
    reportSettings?: Maybe<ReportSettingsNode>;
    userLogs?: Maybe<Array<Maybe<UserLogNode>>>;
    userStats?: Maybe<UserStatsNode>;
    users?: Maybe<UserList>;
};

export type QueryCashiersArgs = {
    filters?: InputMaybe<CashierFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<UserSortEnum>>>;
};

export type QueryExchangeArgs = {
    id: Scalars['String']['input'];
};

export type QueryExchangeCalcArgs = {
    input: ExchangeCalcInput;
};

export type QueryExchangeFiatStatsArgs = {
    officeId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryExchangePairsArgs = {
    filters?: InputMaybe<ExchangePairFilterInput>;
};

export type QueryExchangesArgs = {
    filters?: InputMaybe<ExchangeFilterInput>;
    limit?: Scalars['Int']['input'];
    skip?: Scalars['Int']['input'];
};

export type QueryOfficeArgs = {
    id: Scalars['Int']['input'];
};

export type QueryOfficesArgs = {
    filters?: InputMaybe<OfficeFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<OfficeSortEnum>>>;
};

export type QueryUserLogsArgs = {
    filters?: InputMaybe<UserLogFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<UserLogSortEnum>>>;
};

export type QueryUsersArgs = {
    filters?: InputMaybe<UserFilterInput>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Array<InputMaybe<UserSortEnum>>>;
};

export type RefreshTokenOutput = RefreshTokenSuccess;

export type RefreshTokenSuccess = {
    __typename: 'RefreshTokenSuccess';
    me?: Maybe<UserNode>;
    token: Scalars['String']['output'];
};

export type RefundNode = {
    __typename: 'RefundNode';
    amount: Scalars['String']['output'];
    receiver: Scalars['String']['output'];
    transferId: Scalars['String']['output'];
    txid: Scalars['String']['output'];
};

export type ReportSettingsNode = {
    __typename: 'ReportSettingsNode';
    email: Scalars['String']['output'];
    lastReport?: Maybe<Scalars['DateTime']['output']>;
    time: Scalars['String']['output'];
    timeZone: Scalars['String']['output'];
};

export type ReportSuccess = {
    __typename: 'ReportSuccess';
    type: Scalars['String']['output'];
    uid: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type ResetPasswordInput = {
    email: Scalars['String']['input'];
};

export type ResetPasswordOutput = NoResendAttemptsError | ResetPasswordSuccess | UserNotFoundError;

export type ResetPasswordSuccess = {
    __typename: 'ResetPasswordSuccess';
    email: Scalars['String']['output'];
};

export type SigninInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
};

export type SigninOutput = InvalidCredentialsError | LoginIsEmptyError | SigninSuccess;

export type SigninSuccess = {
    __typename: 'SigninSuccess';
    me?: Maybe<UserNode>;
    token: Scalars['String']['output'];
};

export type Subscription = {
    __typename: 'Subscription';
    connectByWallet?: Maybe<WalletConnectionUpdate>;
    exchangeStatus?: Maybe<ExchangeNode>;
    user?: Maybe<UserNode>;
};

export type SubscriptionConnectByWalletArgs = {
    verificationCode: Scalars['String']['input'];
};

export type SubscriptionExchangeStatusArgs = {
    exchangeId: Scalars['String']['input'];
};

export type UnderMaintenanceError = {
    __typename: 'UnderMaintenanceError';
    errorMessage: Scalars['String']['output'];
};

export type UpdateOfficeInput = {
    address?: InputMaybe<Scalars['String']['input']>;
    city?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    officeId: Scalars['Int']['input'];
};

export type UpdateOfficeOutput = InvalidParamsError | OfficeNotFoundError | UpdateOfficeSuccess;

export type UpdateOfficeSuccess = {
    __typename: 'UpdateOfficeSuccess';
    office: OfficeNode;
};

export type UpdatePairSettingsOutput =
    | ExchangePairNotFoundError
    | InvalidParamsError
    | OfficeNotFoundError
    | UpdatePairSettingsSuccess;

export type UpdatePairSettingsSuccess = {
    __typename: 'UpdatePairSettingsSuccess';
    settings?: Maybe<ExchangePairSettingsNode>;
};

export type UpdateReportSettingsInput = {
    email: Scalars['String']['input'];
    time: Scalars['String']['input'];
    timeZone: Scalars['String']['input'];
};

export type UpdateReportSettingsSuccess = {
    __typename: 'UpdateReportSettingsSuccess';
    settings: ReportSettingsNode;
};

export type UpdateUserInput = {
    email: Scalars['String']['input'];
    isActive?: InputMaybe<Scalars['Boolean']['input']>;
    officeId?: InputMaybe<Scalars['Int']['input']>;
    password?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<UserRole>;
    userId: Scalars['String']['input'];
};

export type UpdateUserOutput =
    | InvalidCredentialsError
    | InvalidEmailError
    | InvalidParamsError
    | UpdateUserSuccess
    | UserAlreadyExistsError
    | UserNotFoundError;

export type UpdateUserSuccess = {
    __typename: 'UpdateUserSuccess';
    user: UserNode;
};

export type UserAlreadyExistsError = {
    __typename: 'UserAlreadyExistsError';
    errorMessage: Scalars['String']['output'];
};

export type UserFilterInput = {
    email?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isCashier?: InputMaybe<Scalars['Boolean']['input']>;
    isUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
    officeId?: InputMaybe<Scalars['Int']['input']>;
    role?: InputMaybe<UserRole>;
    search?: InputMaybe<Scalars['String']['input']>;
};

export type UserIsInOtherOfficeError = {
    __typename: 'UserIsInOtherOfficeError';
    errorMessage: Scalars['String']['output'];
};

export type UserList = {
    __typename: 'UserList';
    pageInfo: PageInfo;
    users: Array<Maybe<UserNode>>;
};

export type UserLogFilterInput = {
    ipAddress?: InputMaybe<Scalars['String']['input']>;
    officeId?: InputMaybe<Scalars['Int']['input']>;
    user?: InputMaybe<Scalars['String']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserLogNode = {
    __typename: 'UserLogNode';
    actionType: ActionType;
    browser?: Maybe<Scalars['String']['output']>;
    created: Scalars['DateTime']['output'];
    device?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    ipAddress: Scalars['String']['output'];
    office?: Maybe<LogOfficeNode>;
    os?: Maybe<Scalars['String']['output']>;
    relatedId?: Maybe<Scalars['String']['output']>;
    user: LogUserNode;
};

export enum UserLogSortEnum {
    Created = 'CREATED',
    CreatedDesc = 'CREATED_DESC'
}

export type UserNode = {
    __typename: 'UserNode';
    created: Scalars['DateTime']['output'];
    email?: Maybe<Scalars['String']['output']>;
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    isActive?: Maybe<Scalars['Boolean']['output']>;
    office?: Maybe<OfficeNode>;
    role?: Maybe<UserRole>;
};

export type UserNotFoundError = {
    __typename: 'UserNotFoundError';
    errorMessage: Scalars['String']['output'];
};

export type UserPeriodStatsNode = {
    __typename: 'UserPeriodStatsNode';
    activated?: Maybe<Scalars['Int']['output']>;
    registered?: Maybe<Scalars['Int']['output']>;
};

export enum UserRole {
    Administrator = 'ADMINISTRATOR',
    Cashier = 'CASHIER',
    Owner = 'OWNER'
}

export enum UserSortEnum {
    Created = 'CREATED',
    CreatedDesc = 'CREATED_DESC',
    Email = 'EMAIL',
    EmailDesc = 'EMAIL_DESC'
}

export type UserStatsNode = {
    __typename: 'UserStatsNode';
    day?: Maybe<UserPeriodStatsNode>;
    month?: Maybe<UserPeriodStatsNode>;
    week?: Maybe<UserPeriodStatsNode>;
};

export type VerifyCodeExpiredError = {
    __typename: 'VerifyCodeExpiredError';
    errorMessage: Scalars['String']['output'];
    hasResendAttempts: Scalars['Boolean']['output'];
};

export type VerifyCodeInput = {
    code: Scalars['String']['input'];
    verifyKey?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyCodeOutput =
    | InvalidJwtError
    | InvalidVerifyCodeError
    | NoConfirmationAttemptsError
    | UserAlreadyExistsError
    | VerifyCodeExpiredError
    | VerifyCodeSuccess;

export type VerifyCodeSuccess = {
    __typename: 'VerifyCodeSuccess';
    me?: Maybe<UserNode>;
    token: Scalars['String']['output'];
};

export type WalletConnectionSuccess = {
    __typename: 'WalletConnectionSuccess';
    relatedId?: Maybe<Scalars['String']['output']>;
    token?: Maybe<Scalars['String']['output']>;
};

export type WalletConnectionUpdate =
    | ConfirmationFailedError
    | EosAccountAlreadyTakenError
    | UserNotFoundError
    | WalletConnectionSuccess
    | WalletLinkOutdatedError;

export type WalletLinkOutdatedError = {
    __typename: 'WalletLinkOutdatedError';
    errorMessage: Scalars['String']['output'];
};

export enum WalletName {
    Anchor = 'ANCHOR',
    Kalyna = 'KALYNA',
    Malinka = 'MALINKA',
    Paycash = 'PAYCASH',
    TokenPoket = 'TOKEN_POKET'
}

export type WrongExchangeStatusError = {
    __typename: 'WrongExchangeStatusError';
    errorMessage: Scalars['String']['output'];
};

export type ExchangeFragment = {
    __typename: 'ExchangeNode';
    id?: string | null;
    userId: string;
    officeId: number;
    created: any;
    updated: any;
    status: ExchangeStatusEnum;
    type: ExchangeTypeEnum;
    eosAccount?: string | null;
    txid?: string | null;
    sequentNumber?: number | null;
    paid?: any | null;
    inputAmount: string;
    inputCurrency: string;
    outputAmount: string;
    outputCurrency: string;
    exchangePairId: string;
    user?: { __typename: 'LogUserNode'; email: string } | null;
    office?: {
        __typename: 'LogOfficeNode';
        id: string;
        address?: string | null;
        city?: string | null;
        name?: string | null;
    } | null;
    paymentParams?: {
        __typename: 'PaymentParamsNode';
        receiver: string;
        memo?: string | null;
        amount: string;
        expirationDate?: string | null;
        blockchain?: string | null;
        metaInfo: string;
    } | null;
};

export type MeFragment = {
    __typename: 'UserNode';
    id?: string | null;
    email?: string | null;
    role?: UserRole | null;
    created: any;
    office?: {
        __typename: 'OfficeNode';
        id: number;
        name?: string | null;
        address?: string | null;
        city?: string | null;
    } | null;
};

export type AcceptCashMutationVariables = Exact<{
    input: AcceptCashInput;
}>;

export type AcceptCashMutation = {
    __typename: 'Mutations';
    acceptCash?:
        | {
              __typename: 'AcceptCashSuccess';
              exchange: { __typename: 'ExchangeNode'; id?: string | null; status: ExchangeStatusEnum };
          }
        | { __typename: 'ExchangeNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidCredentialsError'; errorMessage: string }
        | { __typename: 'WrongExchangeStatusError'; errorMessage: string }
        | null;
};

export type AskForWalletMutationVariables = Exact<{
    input: AskForWalletInput;
}>;

export type AskForWalletMutation = {
    __typename: 'Mutations';
    askForWallet?:
        | {
              __typename: 'AskForWalletSuccess';
              deepLink?: string | null;
              qrData?: string | null;
              verificationCode: string;
          }
        | { __typename: 'DeeplinkNotGeneratedError'; errorMessage: string }
        | { __typename: 'InvalidParamsError' }
        | null;
};

export type ChangePasswordMutationVariables = Exact<{
    oldPassword: Scalars['String']['input'];
    newPassword: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = {
    __typename: 'Mutations';
    changePassword?:
        | {
              __typename: 'ChangePasswordSuccess';
              me: { __typename: 'UserNode'; id?: string | null; email?: string | null; role?: UserRole | null };
          }
        | { __typename: 'InvalidCredentialsError'; errorMessage: string }
        | { __typename: 'InvalidPasswordError'; errorMessage: string }
        | null;
};

export type ConfirmPasswordResetMutationVariables = Exact<{
    password: Scalars['String']['input'];
}>;

export type ConfirmPasswordResetMutation = {
    __typename: 'Mutations';
    confirmPasswordReset?:
        | {
              __typename: 'ConfirmPasswordResetSuccess';
              token?: string | null;
              me?: { __typename: 'UserNode'; id?: string | null; email?: string | null; role?: UserRole | null } | null;
          }
        | { __typename: 'InvalidJWTError'; errorMessage: string }
        | { __typename: 'InvalidPasswordError'; errorMessage: string }
        | { __typename: 'InvalidVerifyCodeError'; errorMessage: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type CreateOfficeMutationVariables = Exact<{
    input: CreateOfficeInput;
}>;

export type CreateOfficeMutation = {
    __typename: 'Mutations';
    createOffice?:
        | {
              __typename: 'CreateOfficeSuccess';
              office: {
                  __typename: 'OfficeNode';
                  id: number;
                  name?: string | null;
                  address?: string | null;
                  city?: string | null;
                  administrators?: Array<{
                      __typename: 'UserNode';
                      id?: string | null;
                      email?: string | null;
                  } | null> | null;
              };
          }
        | { __typename: 'InvalidParamsError'; errorMessage: string; paramName?: string | null }
        | { __typename: 'OfficeNotFoundError' }
        | { __typename: 'UserIsInOtherOfficeError'; errorMessage: string }
        | { __typename: 'UserNotFoundError' }
        | null;
};

export type CreateUserMutationVariables = Exact<{
    input: CreateUserInput;
}>;

export type CreateUserMutation = {
    __typename: 'Mutations';
    createUser?:
        | {
              __typename: 'CreateUserSuccess';
              user: {
                  __typename: 'UserNode';
                  id?: string | null;
                  email?: string | null;
                  role?: UserRole | null;
                  office?: { __typename: 'OfficeNode'; id: number } | null;
              };
          }
        | { __typename: 'InvalidCredentialsError' }
        | { __typename: 'InvalidEmailError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string; paramName?: string | null }
        | { __typename: 'OfficeNotFoundError' }
        | { __typename: 'UserAlreadyExistsError'; errorMessage: string }
        | null;
};

export type DeleteOfficeMutationVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type DeleteOfficeMutation = {
    __typename: 'Mutations';
    deleteOffice?:
        | { __typename: 'DeleteOfficeSuccess'; officeId: string }
        | { __typename: 'OfficeNotFoundError'; errorMessage: string }
        | null;
};

export type DeleteUserMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type DeleteUserMutation = {
    __typename: 'Mutations';
    deleteUser?:
        | { __typename: 'DeleteUserSuccess'; userId: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type MakeExchangeMutationVariables = Exact<{
    input: MakeExchangeInput;
}>;

export type MakeExchangeMutation = {
    __typename: 'Mutations';
    makeExchange?:
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'ExchangeAmountTooHighError'; errorMessage: string }
        | { __typename: 'ExchangeAmountTooLowError'; errorMessage: string }
        | { __typename: 'ExchangePairNotFoundError'; errorMessage: string }
        | { __typename: 'ExchangePairNotPresentError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string }
        | { __typename: 'InvalidTransferParamsError'; errorMessage: string }
        | {
              __typename: 'MakeExchangeSuccess';
              exchange: {
                  __typename: 'ExchangeNode';
                  id?: string | null;
                  userId: string;
                  officeId: number;
                  pc4storeId?: string | null;
                  created: any;
                  updated: any;
                  status: ExchangeStatusEnum;
                  type: ExchangeTypeEnum;
                  eosAccount?: string | null;
                  txid?: string | null;
                  exchangePairId: string;
                  paymentParams?: {
                      __typename: 'PaymentParamsNode';
                      receiver: string;
                      memo?: string | null;
                      amount: string;
                      expirationDate?: string | null;
                      blockchain?: string | null;
                      metaInfo: string;
                  } | null;
              };
          }
        | { __typename: 'UnderMaintenanceError'; errorMessage: string }
        | null;
};

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshTokenMutation = {
    __typename: 'Mutations';
    refreshToken?: {
        __typename: 'RefreshTokenSuccess';
        token: string;
        me?: { __typename: 'UserNode'; id?: string | null; email?: string | null; role?: UserRole | null } | null;
    } | null;
};

export type RepoConfigMutationVariables = Exact<{
    settings: UpdateReportSettingsInput;
}>;

export type RepoConfigMutation = {
    __typename: 'Mutations';
    updateReportSettings?: {
        __typename: 'UpdateReportSettingsSuccess';
        settings: { __typename: 'ReportSettingsNode'; email: string; time: string; timeZone: string };
    } | null;
};

export type ReportExchangesMutationVariables = Exact<{
    filters: ExchangeFilterInput;
}>;

export type ReportExchangesMutation = {
    __typename: 'Mutations';
    reportExchanges?: { __typename: 'ReportSuccess'; type: string; uid: string; url: string } | null;
};

export type ResetPasswordMutationVariables = Exact<{
    email: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
    __typename: 'Mutations';
    resetPassword?:
        | { __typename: 'NoResendAttemptsError'; errorMessage: string }
        | { __typename: 'ResetPasswordSuccess'; email: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type SignInMutationVariables = Exact<{
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type SignInMutation = {
    __typename: 'Mutations';
    signin?:
        | { __typename: 'InvalidCredentialsError'; errorMessage: string }
        | { __typename: 'LoginIsEmptyError'; errorMessage: string }
        | {
              __typename: 'SigninSuccess';
              token: string;
              me?: {
                  __typename: 'UserNode';
                  email?: string | null;
                  role?: UserRole | null;
                  office?: { __typename: 'OfficeNode'; id: number } | null;
              } | null;
          }
        | null;
};

export type UpdateOfficeMutationVariables = Exact<{
    input: UpdateOfficeInput;
}>;

export type UpdateOfficeMutation = {
    __typename: 'Mutations';
    updateOffice?:
        | { __typename: 'InvalidParamsError'; errorMessage: string; paramName?: string | null }
        | { __typename: 'OfficeNotFoundError' }
        | {
              __typename: 'UpdateOfficeSuccess';
              office: {
                  __typename: 'OfficeNode';
                  id: number;
                  name?: string | null;
                  address?: string | null;
                  city?: string | null;
              };
          }
        | null;
};

export type UpdatePairsSettingsMutationVariables = Exact<{
    input: PairSettingsInput;
}>;

export type UpdatePairsSettingsMutation = {
    __typename: 'Mutations';
    updatePairSettings?:
        | { __typename: 'ExchangePairNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string; paramName?: string | null }
        | { __typename: 'OfficeNotFoundError'; errorMessage: string }
        | {
              __typename: 'UpdatePairSettingsSuccess';
              settings?: {
                  __typename: 'ExchangePairSettingsNode';
                  maximalAmount?: string | null;
                  minimalAmount?: string | null;
                  feeFix?: string | null;
                  feePercentage?: string | null;
                  limit7d?: string | null;
                  limit24h?: string | null;
                  limit30d?: string | null;
              } | null;
          }
        | null;
};

export type UpdateUserMutationVariables = Exact<{
    input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
    __typename: 'Mutations';
    updateUser?:
        | { __typename: 'InvalidCredentialsError' }
        | { __typename: 'InvalidEmailError'; errorMessage: string }
        | { __typename: 'InvalidParamsError'; errorMessage: string; paramName?: string | null }
        | {
              __typename: 'UpdateUserSuccess';
              user: {
                  __typename: 'UserNode';
                  id?: string | null;
                  email?: string | null;
                  role?: UserRole | null;
                  isActive?: boolean | null;
                  created: any;
                  office?: { __typename: 'OfficeNode'; id: number } | null;
              };
          }
        | { __typename: 'UserAlreadyExistsError'; errorMessage: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type VerifyCodeMutationVariables = Exact<{
    verifyKey: Scalars['String']['input'];
    code: Scalars['String']['input'];
}>;

export type VerifyCodeMutation = {
    __typename: 'Mutations';
    verifyCode?:
        | { __typename: 'InvalidJWTError'; errorMessage: string }
        | { __typename: 'InvalidVerifyCodeError'; errorMessage: string }
        | { __typename: 'NoConfirmationAttemptsError'; errorMessage: string }
        | { __typename: 'UserAlreadyExistsError'; errorMessage: string }
        | { __typename: 'VerifyCodeExpiredError'; errorMessage: string }
        | { __typename: 'VerifyCodeSuccess'; token: string }
        | null;
};

export type CashiersQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    filters?: InputMaybe<CashierFilterInput>;
    sort?: InputMaybe<Array<InputMaybe<UserSortEnum>> | InputMaybe<UserSortEnum>>;
}>;

export type CashiersQuery = {
    __typename: 'Query';
    cashiers?: {
        __typename: 'UserList';
        users: Array<{
            __typename: 'UserNode';
            created: any;
            role?: UserRole | null;
            email?: string | null;
            id?: string | null;
            isActive?: boolean | null;
            office?: { __typename: 'OfficeNode'; id: number } | null;
        } | null>;
    } | null;
};

export type ExchangeQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type ExchangeQuery = {
    __typename: 'Query';
    exchange?: {
        __typename: 'ExchangeNode';
        id?: string | null;
        userId: string;
        officeId: number;
        created: any;
        updated: any;
        status: ExchangeStatusEnum;
        type: ExchangeTypeEnum;
        eosAccount?: string | null;
        txid?: string | null;
        sequentNumber?: number | null;
        paid?: any | null;
        inputAmount: string;
        inputCurrency: string;
        outputAmount: string;
        outputCurrency: string;
        exchangePairId: string;
        user?: { __typename: 'LogUserNode'; email: string } | null;
        office?: {
            __typename: 'LogOfficeNode';
            id: string;
            address?: string | null;
            city?: string | null;
            name?: string | null;
        } | null;
        paymentParams?: {
            __typename: 'PaymentParamsNode';
            receiver: string;
            memo?: string | null;
            amount: string;
            expirationDate?: string | null;
            blockchain?: string | null;
            metaInfo: string;
        } | null;
    } | null;
};

export type ExchangesQueryVariables = Exact<{
    filters: ExchangeFilterInput;
    skip: Scalars['Int']['input'];
    limit: Scalars['Int']['input'];
}>;

export type ExchangesQuery = {
    __typename: 'Query';
    exchanges?: {
        __typename: 'ExchangeList';
        exchanges: Array<{
            __typename: 'ExchangeNode';
            id?: string | null;
            userId: string;
            officeId: number;
            created: any;
            updated: any;
            status: ExchangeStatusEnum;
            type: ExchangeTypeEnum;
            eosAccount?: string | null;
            txid?: string | null;
            sequentNumber?: number | null;
            paid?: any | null;
            inputAmount: string;
            inputCurrency: string;
            outputAmount: string;
            outputCurrency: string;
            exchangePairId: string;
            user?: { __typename: 'LogUserNode'; email: string } | null;
            office?: {
                __typename: 'LogOfficeNode';
                id: string;
                address?: string | null;
                city?: string | null;
                name?: string | null;
            } | null;
            paymentParams?: {
                __typename: 'PaymentParamsNode';
                receiver: string;
                memo?: string | null;
                amount: string;
                expirationDate?: string | null;
                blockchain?: string | null;
                metaInfo: string;
            } | null;
        } | null>;
        pageInfo: { __typename: 'PageInfo'; totalFiltered?: number | null; total?: number | null };
    } | null;
};

export type ExchangeCalcQueryVariables = Exact<{
    input: ExchangeCalcInput;
}>;

export type ExchangeCalcQuery = {
    __typename: 'Query';
    exchangeCalc?:
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'ExchangeAmountTooHighError'; errorMessage: string }
        | { __typename: 'ExchangeAmountTooLowError'; errorMessage: string }
        | {
              __typename: 'ExchangeCalcNode';
              amountToPay?: string | null;
              amountToReceive?: string | null;
              cryptoAmount?: string | null;
              exchangeRate?: string | null;
              inputFee?: string | null;
              outputFee?: string | null;
          }
        | { __typename: 'ExchangePairNotFoundError'; errorMessage: string }
        | { __typename: 'ExchangePairNotPresentError'; errorMessage: string }
        | { __typename: 'InvalidParamsError' }
        | { __typename: 'InvalidTransferParamsError'; errorMessage: string }
        | { __typename: 'UnderMaintenanceError'; errorMessage: string }
        | null;
};

export type ExchangeFiatStatsQueryVariables = Exact<{
    officeId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ExchangeFiatStatsQuery = {
    __typename: 'Query';
    exchangeFiatStats?: {
        __typename: 'ExchangeFiatStatsNode';
        stats?: Array<{
            __typename: 'FiatCurrencyStatsNode';
            fiatName: string;
            day: {
                __typename: 'PeriodStatisticsNode';
                total: string;
                deposit: string;
                depositCount: number;
                withdraw: string;
                withdrawCount: number;
            };
            week: {
                __typename: 'PeriodStatisticsNode';
                total: string;
                deposit: string;
                depositCount: number;
                withdraw: string;
                withdrawCount: number;
            };
            month: {
                __typename: 'PeriodStatisticsNode';
                total: string;
                deposit: string;
                depositCount: number;
                withdraw: string;
                withdrawCount: number;
            };
        } | null> | null;
    } | null;
};

export type ExchangePairsQueryVariables = Exact<{
    officeId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ExchangePairsQuery = {
    __typename: 'Query';
    exchangePairs?: Array<{
        __typename: 'ExchangePairNode';
        id?: string | null;
        type: ExchangeTypeEnum;
        exchangeRate?: string | null;
        fiatName: string;
        isEnabled?: boolean | null;
        isAvailable?: boolean | null;
        settings: {
            __typename: 'ExchangePairSettingsNode';
            feeFix?: string | null;
            feePercentage?: string | null;
            limit7d?: string | null;
            limit24h?: string | null;
            limit30d?: string | null;
            maximalAmount?: string | null;
            minimalAmount?: string | null;
        };
        currency?: {
            __typename: 'CurrencyNode';
            name?: string | null;
            feeFix?: string | null;
            feePercentage?: string | null;
            withdrawFeeFix?: string | null;
            withdrawFeePercentage?: string | null;
        } | null;
    } | null> | null;
};

export type ExchangeSettingsQueryVariables = Exact<{
    officeId: Scalars['Int']['input'];
}>;

export type ExchangeSettingsQuery = {
    __typename: 'Query';
    exchangePairs?: Array<{
        __typename: 'ExchangePairNode';
        settings: {
            __typename: 'ExchangePairSettingsNode';
            feeFix?: string | null;
            feePercentage?: string | null;
            limit24h?: string | null;
            limit7d?: string | null;
            limit30d?: string | null;
            minimalAmount?: string | null;
            maximalAmount?: string | null;
        };
    } | null> | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
    __typename: 'Query';
    me?: {
        __typename: 'UserNode';
        id?: string | null;
        email?: string | null;
        role?: UserRole | null;
        created: any;
        office?: {
            __typename: 'OfficeNode';
            id: number;
            name?: string | null;
            address?: string | null;
            city?: string | null;
        } | null;
    } | null;
};

export type OfficeQueryVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type OfficeQuery = {
    __typename: 'Query';
    office?: {
        __typename: 'OfficeNode';
        id: number;
        administrators?: Array<{
            __typename: 'UserNode';
            id?: string | null;
            created: any;
            role?: UserRole | null;
            email?: string | null;
        } | null> | null;
        cashiers?: Array<{
            __typename: 'UserNode';
            id?: string | null;
            email?: string | null;
            role?: UserRole | null;
            created: any;
        } | null> | null;
    } | null;
};

export type OfficesQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    filters?: InputMaybe<OfficeFilterInput>;
    sort?: InputMaybe<Array<InputMaybe<OfficeSortEnum>> | InputMaybe<OfficeSortEnum>>;
}>;

export type OfficesQuery = {
    __typename: 'Query';
    offices?: {
        __typename: 'OfficeList';
        pageInfo: { __typename: 'PageInfo'; totalFiltered?: number | null };
        offices: Array<{
            __typename: 'OfficeNode';
            id: number;
            name?: string | null;
            city?: string | null;
            address?: string | null;
            created: any;
            administrators?: Array<{ __typename: 'UserNode'; id?: string | null; email?: string | null } | null> | null;
        } | null>;
    } | null;
};

export type ReportSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportSettingsQuery = {
    __typename: 'Query';
    reportSettings?: { __typename: 'ReportSettingsNode'; email: string; time: string; timeZone: string } | null;
};

export type UsersQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    filters?: InputMaybe<UserFilterInput>;
    sort?: InputMaybe<Array<InputMaybe<UserSortEnum>> | InputMaybe<UserSortEnum>>;
}>;

export type UsersQuery = {
    __typename: 'Query';
    users?: {
        __typename: 'UserList';
        users: Array<{
            __typename: 'UserNode';
            id?: string | null;
            role?: UserRole | null;
            created: any;
            email?: string | null;
            isActive?: boolean | null;
            office?: {
                __typename: 'OfficeNode';
                id: number;
                city?: string | null;
                name?: string | null;
                address?: string | null;
            } | null;
        } | null>;
    } | null;
};

export type UserLogsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    filters?: InputMaybe<UserLogFilterInput>;
    sort?: InputMaybe<Array<InputMaybe<UserLogSortEnum>> | InputMaybe<UserLogSortEnum>>;
}>;

export type UserLogsQuery = {
    __typename: 'Query';
    userLogs?: Array<{
        __typename: 'UserLogNode';
        relatedId?: string | null;
        id: string;
        created: any;
        actionType: ActionType;
        browser?: string | null;
        device?: string | null;
        ipAddress: string;
        os?: string | null;
        office?: {
            __typename: 'LogOfficeNode';
            city?: string | null;
            address?: string | null;
            id: string;
            name?: string | null;
        } | null;
        user: { __typename: 'LogUserNode'; id: string; email: string };
    } | null> | null;
};

export type ConnectByWalletWsSubscriptionVariables = Exact<{
    verificationCode: Scalars['String']['input'];
}>;

export type ConnectByWalletWsSubscription = {
    __typename: 'Subscription';
    connectByWallet?:
        | { __typename: 'ConfirmationFailedError'; errorMessage: string }
        | { __typename: 'EosAccountAlreadyTakenError'; errorMessage: string }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | { __typename: 'WalletConnectionSuccess'; token?: string | null; relatedId?: string | null }
        | { __typename: 'WalletLinkOutdatedError'; errorMessage: string }
        | null;
};

export type ExchangeStatusSubscriptionVariables = Exact<{
    exchangeId: Scalars['String']['input'];
}>;

export type ExchangeStatusSubscription = {
    __typename: 'Subscription';
    exchangeStatus?: { __typename: 'ExchangeNode'; id?: string | null; status: ExchangeStatusEnum } | null;
};

export const ExchangeFragmentDoc = gql`
    fragment Exchange on ExchangeNode {
        id
        userId
        officeId
        created
        updated
        status
        type
        eosAccount
        txid
        sequentNumber
        paid
        inputAmount
        inputCurrency
        outputAmount
        outputCurrency
        exchangePairId
        user {
            email
        }
        office {
            id
            address
            city
            name
        }
        paymentParams {
            receiver
            memo
            amount
            expirationDate
            blockchain
            metaInfo
        }
    }
`;
export const MeFragmentDoc = gql`
    fragment Me on UserNode {
        id
        email
        role
        created
        office {
            id
            name
            address
            city
        }
    }
`;
export const AcceptCashDocument = gql`
    mutation AcceptCash($input: AcceptCashInput!) {
        acceptCash(input: $input) {
            __typename
            ... on AcceptCashSuccess {
                exchange {
                    id
                    status
                }
            }
            ... on ExchangeNotFoundError {
                errorMessage
            }
            ... on WrongExchangeStatusError {
                errorMessage
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
        }
    }
`;
export type AcceptCashMutationFn = Apollo.MutationFunction<AcceptCashMutation, AcceptCashMutationVariables>;

/**
 * __useAcceptCashMutation__
 *
 * To run a mutation, you first call `useAcceptCashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCashMutation, { data, loading, error }] = useAcceptCashMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCashMutation(
    baseOptions?: Apollo.MutationHookOptions<AcceptCashMutation, AcceptCashMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AcceptCashMutation, AcceptCashMutationVariables>(AcceptCashDocument, options);
}
export type AcceptCashMutationHookResult = ReturnType<typeof useAcceptCashMutation>;
export type AcceptCashMutationResult = Apollo.MutationResult<AcceptCashMutation>;
export type AcceptCashMutationOptions = Apollo.BaseMutationOptions<AcceptCashMutation, AcceptCashMutationVariables>;
export const AskForWalletDocument = gql`
    mutation AskForWallet($input: AskForWalletInput!) {
        askForWallet(input: $input) {
            ... on AskForWalletSuccess {
                deepLink
                qrData
                verificationCode
            }
            ... on DeeplinkNotGeneratedError {
                errorMessage
            }
        }
    }
`;
export type AskForWalletMutationFn = Apollo.MutationFunction<AskForWalletMutation, AskForWalletMutationVariables>;

/**
 * __useAskForWalletMutation__
 *
 * To run a mutation, you first call `useAskForWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAskForWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [askForWalletMutation, { data, loading, error }] = useAskForWalletMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAskForWalletMutation(
    baseOptions?: Apollo.MutationHookOptions<AskForWalletMutation, AskForWalletMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AskForWalletMutation, AskForWalletMutationVariables>(AskForWalletDocument, options);
}
export type AskForWalletMutationHookResult = ReturnType<typeof useAskForWalletMutation>;
export type AskForWalletMutationResult = Apollo.MutationResult<AskForWalletMutation>;
export type AskForWalletMutationOptions = Apollo.BaseMutationOptions<
    AskForWalletMutation,
    AskForWalletMutationVariables
>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
        changePassword(input: { oldPassword: $oldPassword, newPassword: $newPassword }) {
            __typename
            ... on ChangePasswordSuccess {
                me {
                    id
                    email
                    role
                }
            }
            ... on InvalidPasswordError {
                errorMessage
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const ConfirmPasswordResetDocument = gql`
    mutation ConfirmPasswordReset($password: String!) {
        confirmPasswordReset(input: { password: $password }) {
            __typename
            ... on ConfirmPasswordResetSuccess {
                me {
                    id
                    email
                    role
                }
                token
            }
            ... on InvalidJWTError {
                errorMessage
            }
            ... on InvalidVerifyCodeError {
                errorMessage
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on InvalidPasswordError {
                errorMessage
            }
        }
    }
`;
export type ConfirmPasswordResetMutationFn = Apollo.MutationFunction<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
>;

/**
 * __useConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPasswordResetMutation, { data, loading, error }] = useConfirmPasswordResetMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useConfirmPasswordResetMutation(
    baseOptions?: Apollo.MutationHookOptions<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>(
        ConfirmPasswordResetDocument,
        options
    );
}
export type ConfirmPasswordResetMutationHookResult = ReturnType<typeof useConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationResult = Apollo.MutationResult<ConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
>;
export const CreateOfficeDocument = gql`
    mutation CreateOffice($input: CreateOfficeInput!) {
        createOffice(input: $input) {
            __typename
            ... on CreateOfficeSuccess {
                office {
                    id
                    name
                    address
                    city
                    administrators {
                        id
                        email
                    }
                }
            }
            ... on InvalidParamsError {
                errorMessage
                paramName
            }
            ... on UserIsInOtherOfficeError {
                errorMessage
            }
        }
    }
`;
export type CreateOfficeMutationFn = Apollo.MutationFunction<CreateOfficeMutation, CreateOfficeMutationVariables>;

/**
 * __useCreateOfficeMutation__
 *
 * To run a mutation, you first call `useCreateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficeMutation, { data, loading, error }] = useCreateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfficeMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateOfficeMutation, CreateOfficeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateOfficeMutation, CreateOfficeMutationVariables>(CreateOfficeDocument, options);
}
export type CreateOfficeMutationHookResult = ReturnType<typeof useCreateOfficeMutation>;
export type CreateOfficeMutationResult = Apollo.MutationResult<CreateOfficeMutation>;
export type CreateOfficeMutationOptions = Apollo.BaseMutationOptions<
    CreateOfficeMutation,
    CreateOfficeMutationVariables
>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
        createUser(input: $input) {
            __typename
            ... on CreateUserSuccess {
                user {
                    id
                    email
                    role
                    office {
                        id
                    }
                }
            }
            ... on InvalidParamsError {
                errorMessage
                paramName
            }
            ... on InvalidEmailError {
                errorMessage
            }
            ... on UserAlreadyExistsError {
                errorMessage
            }
        }
    }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteOfficeDocument = gql`
    mutation DeleteOffice($id: Int!) {
        deleteOffice(officeId: $id) {
            __typename
            ... on DeleteOfficeSuccess {
                officeId
            }
            ... on OfficeNotFoundError {
                errorMessage
            }
        }
    }
`;
export type DeleteOfficeMutationFn = Apollo.MutationFunction<DeleteOfficeMutation, DeleteOfficeMutationVariables>;

/**
 * __useDeleteOfficeMutation__
 *
 * To run a mutation, you first call `useDeleteOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfficeMutation, { data, loading, error }] = useDeleteOfficeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfficeMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteOfficeMutation, DeleteOfficeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteOfficeMutation, DeleteOfficeMutationVariables>(DeleteOfficeDocument, options);
}
export type DeleteOfficeMutationHookResult = ReturnType<typeof useDeleteOfficeMutation>;
export type DeleteOfficeMutationResult = Apollo.MutationResult<DeleteOfficeMutation>;
export type DeleteOfficeMutationOptions = Apollo.BaseMutationOptions<
    DeleteOfficeMutation,
    DeleteOfficeMutationVariables
>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
        deleteUser(userId: $id) {
            __typename
            ... on DeleteUserSuccess {
                userId
            }
            ... on UserNotFoundError {
                errorMessage
            }
        }
    }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const MakeExchangeDocument = gql`
    mutation MakeExchange($input: MakeExchangeInput!) {
        makeExchange(input: $input) {
            __typename
            ... on MakeExchangeSuccess {
                exchange {
                    id
                    userId
                    officeId
                    pc4storeId
                    created
                    updated
                    status
                    type
                    eosAccount
                    txid
                    exchangePairId
                    paymentParams {
                        receiver
                        memo
                        amount
                        expirationDate
                        blockchain
                        metaInfo
                    }
                }
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on ExchangePairNotPresentError {
                errorMessage
            }
            ... on ExchangeAmountTooLowError {
                errorMessage
            }
            ... on ExchangeAmountTooHighError {
                errorMessage
            }
            ... on InvalidTransferParamsError {
                errorMessage
            }
            ... on ExchangePairNotFoundError {
                errorMessage
            }
            ... on UnderMaintenanceError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
            }
        }
    }
`;
export type MakeExchangeMutationFn = Apollo.MutationFunction<MakeExchangeMutation, MakeExchangeMutationVariables>;

/**
 * __useMakeExchangeMutation__
 *
 * To run a mutation, you first call `useMakeExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeExchangeMutation, { data, loading, error }] = useMakeExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeExchangeMutation(
    baseOptions?: Apollo.MutationHookOptions<MakeExchangeMutation, MakeExchangeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<MakeExchangeMutation, MakeExchangeMutationVariables>(MakeExchangeDocument, options);
}
export type MakeExchangeMutationHookResult = ReturnType<typeof useMakeExchangeMutation>;
export type MakeExchangeMutationResult = Apollo.MutationResult<MakeExchangeMutation>;
export type MakeExchangeMutationOptions = Apollo.BaseMutationOptions<
    MakeExchangeMutation,
    MakeExchangeMutationVariables
>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
        refreshToken {
            __typename
            ... on RefreshTokenSuccess {
                me {
                    id
                    email
                    role
                }
                token
            }
        }
    }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
>;
export const RepoConfigDocument = gql`
    mutation RepoConfig($settings: UpdateReportSettingsInput!) {
        updateReportSettings(settings: $settings) {
            settings {
                email
                time
                timeZone
            }
        }
    }
`;
export type RepoConfigMutationFn = Apollo.MutationFunction<RepoConfigMutation, RepoConfigMutationVariables>;

/**
 * __useRepoConfigMutation__
 *
 * To run a mutation, you first call `useRepoConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepoConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [repoConfigMutation, { data, loading, error }] = useRepoConfigMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useRepoConfigMutation(
    baseOptions?: Apollo.MutationHookOptions<RepoConfigMutation, RepoConfigMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RepoConfigMutation, RepoConfigMutationVariables>(RepoConfigDocument, options);
}
export type RepoConfigMutationHookResult = ReturnType<typeof useRepoConfigMutation>;
export type RepoConfigMutationResult = Apollo.MutationResult<RepoConfigMutation>;
export type RepoConfigMutationOptions = Apollo.BaseMutationOptions<RepoConfigMutation, RepoConfigMutationVariables>;
export const ReportExchangesDocument = gql`
    mutation ReportExchanges($filters: ExchangeFilterInput!) {
        reportExchanges(filters: $filters) {
            type
            uid
            url
        }
    }
`;
export type ReportExchangesMutationFn = Apollo.MutationFunction<
    ReportExchangesMutation,
    ReportExchangesMutationVariables
>;

/**
 * __useReportExchangesMutation__
 *
 * To run a mutation, you first call `useReportExchangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportExchangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportExchangesMutation, { data, loading, error }] = useReportExchangesMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useReportExchangesMutation(
    baseOptions?: Apollo.MutationHookOptions<ReportExchangesMutation, ReportExchangesMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReportExchangesMutation, ReportExchangesMutationVariables>(
        ReportExchangesDocument,
        options
    );
}
export type ReportExchangesMutationHookResult = ReturnType<typeof useReportExchangesMutation>;
export type ReportExchangesMutationResult = Apollo.MutationResult<ReportExchangesMutation>;
export type ReportExchangesMutationOptions = Apollo.BaseMutationOptions<
    ReportExchangesMutation,
    ReportExchangesMutationVariables
>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
        resetPassword(input: { email: $email }) {
            __typename
            ... on ResetPasswordSuccess {
                email
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on NoResendAttemptsError {
                errorMessage
            }
        }
    }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;
export const SignInDocument = gql`
    mutation SignIn($email: String!, $password: String!) {
        signin(input: { email: $email, password: $password }) {
            __typename
            ... on SigninSuccess {
                me {
                    email
                    role
                    office {
                        id
                    }
                }
                token
            }
            ... on LoginIsEmptyError {
                errorMessage
            }
            ... on InvalidCredentialsError {
                errorMessage
            }
        }
    }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const UpdateOfficeDocument = gql`
    mutation UpdateOffice($input: UpdateOfficeInput!) {
        updateOffice(input: $input) {
            __typename
            ... on UpdateOfficeSuccess {
                office {
                    id
                    name
                    address
                    city
                }
            }
            ... on InvalidParamsError {
                errorMessage
                paramName
            }
        }
    }
`;
export type UpdateOfficeMutationFn = Apollo.MutationFunction<UpdateOfficeMutation, UpdateOfficeMutationVariables>;

/**
 * __useUpdateOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficeMutation, { data, loading, error }] = useUpdateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfficeMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateOfficeMutation, UpdateOfficeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateOfficeMutation, UpdateOfficeMutationVariables>(UpdateOfficeDocument, options);
}
export type UpdateOfficeMutationHookResult = ReturnType<typeof useUpdateOfficeMutation>;
export type UpdateOfficeMutationResult = Apollo.MutationResult<UpdateOfficeMutation>;
export type UpdateOfficeMutationOptions = Apollo.BaseMutationOptions<
    UpdateOfficeMutation,
    UpdateOfficeMutationVariables
>;
export const UpdatePairsSettingsDocument = gql`
    mutation UpdatePairsSettings($input: PairSettingsInput!) {
        updatePairSettings(input: $input) {
            ... on UpdatePairSettingsSuccess {
                settings {
                    maximalAmount
                    minimalAmount
                    feeFix
                    feePercentage
                    limit7d
                    limit24h
                    limit30d
                }
            }
            ... on ExchangePairNotFoundError {
                errorMessage
            }
            ... on InvalidParamsError {
                errorMessage
                paramName
            }
            ... on OfficeNotFoundError {
                errorMessage
            }
        }
    }
`;
export type UpdatePairsSettingsMutationFn = Apollo.MutationFunction<
    UpdatePairsSettingsMutation,
    UpdatePairsSettingsMutationVariables
>;

/**
 * __useUpdatePairsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePairsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePairsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePairsSettingsMutation, { data, loading, error }] = useUpdatePairsSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePairsSettingsMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePairsSettingsMutation, UpdatePairsSettingsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdatePairsSettingsMutation, UpdatePairsSettingsMutationVariables>(
        UpdatePairsSettingsDocument,
        options
    );
}
export type UpdatePairsSettingsMutationHookResult = ReturnType<typeof useUpdatePairsSettingsMutation>;
export type UpdatePairsSettingsMutationResult = Apollo.MutationResult<UpdatePairsSettingsMutation>;
export type UpdatePairsSettingsMutationOptions = Apollo.BaseMutationOptions<
    UpdatePairsSettingsMutation,
    UpdatePairsSettingsMutationVariables
>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            __typename
            ... on UpdateUserSuccess {
                user {
                    id
                    email
                    role
                    isActive
                    created
                    office {
                        id
                    }
                }
            }
            ... on InvalidParamsError {
                errorMessage
                paramName
            }
            ... on InvalidEmailError {
                errorMessage
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on UserAlreadyExistsError {
                errorMessage
            }
        }
    }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const VerifyCodeDocument = gql`
    mutation VerifyCode($verifyKey: String!, $code: String!) {
        verifyCode(input: { verifyKey: $verifyKey, code: $code }) {
            __typename
            ... on VerifyCodeSuccess {
                token
            }
            ... on InvalidJWTError {
                errorMessage
            }
            ... on InvalidVerifyCodeError {
                errorMessage
            }
            ... on VerifyCodeExpiredError {
                errorMessage
            }
            ... on UserAlreadyExistsError {
                errorMessage
            }
            ... on NoConfirmationAttemptsError {
                errorMessage
            }
        }
    }
`;
export type VerifyCodeMutationFn = Apollo.MutationFunction<VerifyCodeMutation, VerifyCodeMutationVariables>;

/**
 * __useVerifyCodeMutation__
 *
 * To run a mutation, you first call `useVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCodeMutation, { data, loading, error }] = useVerifyCodeMutation({
 *   variables: {
 *      verifyKey: // value for 'verifyKey'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyCodeMutation(
    baseOptions?: Apollo.MutationHookOptions<VerifyCodeMutation, VerifyCodeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<VerifyCodeMutation, VerifyCodeMutationVariables>(VerifyCodeDocument, options);
}
export type VerifyCodeMutationHookResult = ReturnType<typeof useVerifyCodeMutation>;
export type VerifyCodeMutationResult = Apollo.MutationResult<VerifyCodeMutation>;
export type VerifyCodeMutationOptions = Apollo.BaseMutationOptions<VerifyCodeMutation, VerifyCodeMutationVariables>;
export const CashiersDocument = gql`
    query Cashiers($first: Int, $offset: Int, $filters: CashierFilterInput, $sort: [UserSortEnum]) {
        cashiers(filters: $filters, first: $first, offset: $offset, sort: $sort) {
            users {
                created
                role
                email
                id
                office {
                    id
                }
                isActive
            }
        }
    }
`;

/**
 * __useCashiersQuery__
 *
 * To run a query within a React component, call `useCashiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashiersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCashiersQuery(baseOptions?: Apollo.QueryHookOptions<CashiersQuery, CashiersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CashiersQuery, CashiersQueryVariables>(CashiersDocument, options);
}
export function useCashiersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CashiersQuery, CashiersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CashiersQuery, CashiersQueryVariables>(CashiersDocument, options);
}
export type CashiersQueryHookResult = ReturnType<typeof useCashiersQuery>;
export type CashiersLazyQueryHookResult = ReturnType<typeof useCashiersLazyQuery>;
export type CashiersQueryResult = Apollo.QueryResult<CashiersQuery, CashiersQueryVariables>;
export const ExchangeDocument = gql`
    query Exchange($id: String!) {
        exchange(id: $id) {
            ...Exchange
        }
    }
    ${ExchangeFragmentDoc}
`;

/**
 * __useExchangeQuery__
 *
 * To run a query within a React component, call `useExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExchangeQuery(baseOptions: Apollo.QueryHookOptions<ExchangeQuery, ExchangeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangeQuery, ExchangeQueryVariables>(ExchangeDocument, options);
}
export function useExchangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExchangeQuery, ExchangeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangeQuery, ExchangeQueryVariables>(ExchangeDocument, options);
}
export type ExchangeQueryHookResult = ReturnType<typeof useExchangeQuery>;
export type ExchangeLazyQueryHookResult = ReturnType<typeof useExchangeLazyQuery>;
export type ExchangeQueryResult = Apollo.QueryResult<ExchangeQuery, ExchangeQueryVariables>;
export const ExchangesDocument = gql`
    query Exchanges($filters: ExchangeFilterInput!, $skip: Int!, $limit: Int!) {
        exchanges(filters: $filters, skip: $skip, limit: $limit) {
            exchanges {
                ...Exchange
            }
            pageInfo {
                totalFiltered
                total
            }
        }
    }
    ${ExchangeFragmentDoc}
`;

/**
 * __useExchangesQuery__
 *
 * To run a query within a React component, call `useExchangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useExchangesQuery(baseOptions: Apollo.QueryHookOptions<ExchangesQuery, ExchangesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangesQuery, ExchangesQueryVariables>(ExchangesDocument, options);
}
export function useExchangesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExchangesQuery, ExchangesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangesQuery, ExchangesQueryVariables>(ExchangesDocument, options);
}
export type ExchangesQueryHookResult = ReturnType<typeof useExchangesQuery>;
export type ExchangesLazyQueryHookResult = ReturnType<typeof useExchangesLazyQuery>;
export type ExchangesQueryResult = Apollo.QueryResult<ExchangesQuery, ExchangesQueryVariables>;
export const ExchangeCalcDocument = gql`
    query ExchangeCalc($input: ExchangeCalcInput!) {
        exchangeCalc(input: $input) {
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on ExchangePairNotFoundError {
                errorMessage
            }
            ... on ExchangeAmountTooHighError {
                errorMessage
            }
            ... on ExchangeAmountTooLowError {
                errorMessage
            }
            ... on ExchangePairNotPresentError {
                errorMessage
            }
            ... on InvalidTransferParamsError {
                errorMessage
            }
            ... on UnderMaintenanceError {
                errorMessage
            }
            ... on ExchangeCalcNode {
                amountToPay
                amountToReceive
                cryptoAmount
                exchangeRate
                inputFee
                outputFee
            }
        }
    }
`;

/**
 * __useExchangeCalcQuery__
 *
 * To run a query within a React component, call `useExchangeCalcQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeCalcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeCalcQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExchangeCalcQuery(
    baseOptions: Apollo.QueryHookOptions<ExchangeCalcQuery, ExchangeCalcQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangeCalcQuery, ExchangeCalcQueryVariables>(ExchangeCalcDocument, options);
}
export function useExchangeCalcLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExchangeCalcQuery, ExchangeCalcQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangeCalcQuery, ExchangeCalcQueryVariables>(ExchangeCalcDocument, options);
}
export type ExchangeCalcQueryHookResult = ReturnType<typeof useExchangeCalcQuery>;
export type ExchangeCalcLazyQueryHookResult = ReturnType<typeof useExchangeCalcLazyQuery>;
export type ExchangeCalcQueryResult = Apollo.QueryResult<ExchangeCalcQuery, ExchangeCalcQueryVariables>;
export const ExchangeFiatStatsDocument = gql`
    query ExchangeFiatStats($officeId: Int) {
        exchangeFiatStats(officeId: $officeId) {
            __typename
            stats {
                fiatName
                __typename
                day {
                    total
                    deposit
                    depositCount
                    withdraw
                    withdrawCount
                    __typename
                }
                week {
                    total
                    deposit
                    depositCount
                    withdraw
                    withdrawCount
                    __typename
                }
                month {
                    total
                    deposit
                    depositCount
                    withdraw
                    withdrawCount
                    __typename
                }
            }
        }
    }
`;

/**
 * __useExchangeFiatStatsQuery__
 *
 * To run a query within a React component, call `useExchangeFiatStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeFiatStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeFiatStatsQuery({
 *   variables: {
 *      officeId: // value for 'officeId'
 *   },
 * });
 */
export function useExchangeFiatStatsQuery(
    baseOptions?: Apollo.QueryHookOptions<ExchangeFiatStatsQuery, ExchangeFiatStatsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangeFiatStatsQuery, ExchangeFiatStatsQueryVariables>(ExchangeFiatStatsDocument, options);
}
export function useExchangeFiatStatsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExchangeFiatStatsQuery, ExchangeFiatStatsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangeFiatStatsQuery, ExchangeFiatStatsQueryVariables>(
        ExchangeFiatStatsDocument,
        options
    );
}
export type ExchangeFiatStatsQueryHookResult = ReturnType<typeof useExchangeFiatStatsQuery>;
export type ExchangeFiatStatsLazyQueryHookResult = ReturnType<typeof useExchangeFiatStatsLazyQuery>;
export type ExchangeFiatStatsQueryResult = Apollo.QueryResult<ExchangeFiatStatsQuery, ExchangeFiatStatsQueryVariables>;
export const ExchangePairsDocument = gql`
    query ExchangePairs($officeId: Int) {
        exchangePairs {
            settings(officeId: $officeId) {
                feeFix
                feePercentage
                limit7d
                limit24h
                limit30d
                maximalAmount
                minimalAmount
            }
            id
            type
            exchangeRate
            fiatName
            currency {
                name
                feeFix
                feePercentage
                withdrawFeeFix
                withdrawFeePercentage
            }
            isEnabled
            isAvailable
        }
    }
`;

/**
 * __useExchangePairsQuery__
 *
 * To run a query within a React component, call `useExchangePairsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangePairsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangePairsQuery({
 *   variables: {
 *      officeId: // value for 'officeId'
 *   },
 * });
 */
export function useExchangePairsQuery(
    baseOptions?: Apollo.QueryHookOptions<ExchangePairsQuery, ExchangePairsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangePairsQuery, ExchangePairsQueryVariables>(ExchangePairsDocument, options);
}
export function useExchangePairsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExchangePairsQuery, ExchangePairsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangePairsQuery, ExchangePairsQueryVariables>(ExchangePairsDocument, options);
}
export type ExchangePairsQueryHookResult = ReturnType<typeof useExchangePairsQuery>;
export type ExchangePairsLazyQueryHookResult = ReturnType<typeof useExchangePairsLazyQuery>;
export type ExchangePairsQueryResult = Apollo.QueryResult<ExchangePairsQuery, ExchangePairsQueryVariables>;
export const ExchangeSettingsDocument = gql`
    query ExchangeSettings($officeId: Int!) {
        exchangePairs {
            settings(officeId: $officeId) {
                feeFix
                feePercentage
                limit24h
                limit7d
                limit30d
                minimalAmount
                maximalAmount
            }
        }
    }
`;

/**
 * __useExchangeSettingsQuery__
 *
 * To run a query within a React component, call `useExchangeSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeSettingsQuery({
 *   variables: {
 *      officeId: // value for 'officeId'
 *   },
 * });
 */
export function useExchangeSettingsQuery(
    baseOptions: Apollo.QueryHookOptions<ExchangeSettingsQuery, ExchangeSettingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangeSettingsQuery, ExchangeSettingsQueryVariables>(ExchangeSettingsDocument, options);
}
export function useExchangeSettingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExchangeSettingsQuery, ExchangeSettingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangeSettingsQuery, ExchangeSettingsQueryVariables>(
        ExchangeSettingsDocument,
        options
    );
}
export type ExchangeSettingsQueryHookResult = ReturnType<typeof useExchangeSettingsQuery>;
export type ExchangeSettingsLazyQueryHookResult = ReturnType<typeof useExchangeSettingsLazyQuery>;
export type ExchangeSettingsQueryResult = Apollo.QueryResult<ExchangeSettingsQuery, ExchangeSettingsQueryVariables>;
export const MeDocument = gql`
    query Me {
        me {
            ...Me
        }
    }
    ${MeFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const OfficeDocument = gql`
    query Office($id: Int!) {
        office(id: $id) {
            id
            administrators {
                id
                created
                role
                email
            }
            cashiers {
                id
                email
                role
                created
            }
        }
    }
`;

/**
 * __useOfficeQuery__
 *
 * To run a query within a React component, call `useOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfficeQuery(baseOptions: Apollo.QueryHookOptions<OfficeQuery, OfficeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OfficeQuery, OfficeQueryVariables>(OfficeDocument, options);
}
export function useOfficeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficeQuery, OfficeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OfficeQuery, OfficeQueryVariables>(OfficeDocument, options);
}
export type OfficeQueryHookResult = ReturnType<typeof useOfficeQuery>;
export type OfficeLazyQueryHookResult = ReturnType<typeof useOfficeLazyQuery>;
export type OfficeQueryResult = Apollo.QueryResult<OfficeQuery, OfficeQueryVariables>;
export const OfficesDocument = gql`
    query Offices($first: Int, $offset: Int, $filters: OfficeFilterInput, $sort: [OfficeSortEnum]) {
        offices(filters: $filters, sort: $sort, offset: $offset, first: $first) {
            pageInfo {
                totalFiltered
            }
            offices {
                id
                name
                city
                address
                created
                administrators {
                    id
                    email
                }
            }
        }
    }
`;

/**
 * __useOfficesQuery__
 *
 * To run a query within a React component, call `useOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useOfficesQuery(baseOptions?: Apollo.QueryHookOptions<OfficesQuery, OfficesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OfficesQuery, OfficesQueryVariables>(OfficesDocument, options);
}
export function useOfficesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficesQuery, OfficesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OfficesQuery, OfficesQueryVariables>(OfficesDocument, options);
}
export type OfficesQueryHookResult = ReturnType<typeof useOfficesQuery>;
export type OfficesLazyQueryHookResult = ReturnType<typeof useOfficesLazyQuery>;
export type OfficesQueryResult = Apollo.QueryResult<OfficesQuery, OfficesQueryVariables>;
export const ReportSettingsDocument = gql`
    query ReportSettings {
        reportSettings {
            email
            time
            timeZone
        }
    }
`;

/**
 * __useReportSettingsQuery__
 *
 * To run a query within a React component, call `useReportSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportSettingsQuery(
    baseOptions?: Apollo.QueryHookOptions<ReportSettingsQuery, ReportSettingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ReportSettingsQuery, ReportSettingsQueryVariables>(ReportSettingsDocument, options);
}
export function useReportSettingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ReportSettingsQuery, ReportSettingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ReportSettingsQuery, ReportSettingsQueryVariables>(ReportSettingsDocument, options);
}
export type ReportSettingsQueryHookResult = ReturnType<typeof useReportSettingsQuery>;
export type ReportSettingsLazyQueryHookResult = ReturnType<typeof useReportSettingsLazyQuery>;
export type ReportSettingsQueryResult = Apollo.QueryResult<ReportSettingsQuery, ReportSettingsQueryVariables>;
export const UsersDocument = gql`
    query Users($first: Int, $offset: Int, $filters: UserFilterInput, $sort: [UserSortEnum]) {
        users(filters: $filters, first: $first, offset: $offset, sort: $sort) {
            users {
                id
                role
                created
                email
                isActive
                office {
                    id
                    city
                    name
                    address
                }
            }
        }
    }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserLogsDocument = gql`
    query UserLogs($first: Int, $offset: Int, $filters: UserLogFilterInput, $sort: [UserLogSortEnum]) {
        userLogs(filters: $filters, first: $first, offset: $offset, sort: $sort) {
            relatedId
            office {
                city
                address
                id
                name
            }
            id
            created
            user {
                id
                email
            }
            actionType
            browser
            device
            ipAddress
            os
        }
    }
`;

/**
 * __useUserLogsQuery__
 *
 * To run a query within a React component, call `useUserLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLogsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUserLogsQuery(baseOptions?: Apollo.QueryHookOptions<UserLogsQuery, UserLogsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UserLogsQuery, UserLogsQueryVariables>(UserLogsDocument, options);
}
export function useUserLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLogsQuery, UserLogsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UserLogsQuery, UserLogsQueryVariables>(UserLogsDocument, options);
}
export type UserLogsQueryHookResult = ReturnType<typeof useUserLogsQuery>;
export type UserLogsLazyQueryHookResult = ReturnType<typeof useUserLogsLazyQuery>;
export type UserLogsQueryResult = Apollo.QueryResult<UserLogsQuery, UserLogsQueryVariables>;
export const ConnectByWalletWsDocument = gql`
    subscription ConnectByWalletWS($verificationCode: String!) {
        connectByWallet(verificationCode: $verificationCode) {
            ... on WalletConnectionSuccess {
                token
                relatedId
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on ConfirmationFailedError {
                errorMessage
            }
            ... on WalletLinkOutdatedError {
                errorMessage
            }
            ... on EosAccountAlreadyTakenError {
                errorMessage
            }
        }
    }
`;

/**
 * __useConnectByWalletWsSubscription__
 *
 * To run a query within a React component, call `useConnectByWalletWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useConnectByWalletWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectByWalletWsSubscription({
 *   variables: {
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useConnectByWalletWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<ConnectByWalletWsSubscription, ConnectByWalletWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ConnectByWalletWsSubscription, ConnectByWalletWsSubscriptionVariables>(
        ConnectByWalletWsDocument,
        options
    );
}
export type ConnectByWalletWsSubscriptionHookResult = ReturnType<typeof useConnectByWalletWsSubscription>;
export type ConnectByWalletWsSubscriptionResult = Apollo.SubscriptionResult<ConnectByWalletWsSubscription>;
export const ExchangeStatusDocument = gql`
    subscription ExchangeStatus($exchangeId: String!) {
        exchangeStatus(exchangeId: $exchangeId) {
            id
            status
        }
    }
`;

/**
 * __useExchangeStatusSubscription__
 *
 * To run a query within a React component, call `useExchangeStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExchangeStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeStatusSubscription({
 *   variables: {
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function useExchangeStatusSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<ExchangeStatusSubscription, ExchangeStatusSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ExchangeStatusSubscription, ExchangeStatusSubscriptionVariables>(
        ExchangeStatusDocument,
        options
    );
}
export type ExchangeStatusSubscriptionHookResult = ReturnType<typeof useExchangeStatusSubscription>;
export type ExchangeStatusSubscriptionResult = Apollo.SubscriptionResult<ExchangeStatusSubscription>;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        AcceptCashOutput: [
            'AcceptCashSuccess',
            'ExchangeNotFoundError',
            'InvalidCredentialsError',
            'WrongExchangeStatusError'
        ],
        CalculationOutput: [
            'CurrencyNotFoundError',
            'ExchangeAmountTooHighError',
            'ExchangeAmountTooLowError',
            'ExchangeCalcNode',
            'ExchangePairNotFoundError',
            'ExchangePairNotPresentError',
            'InvalidParamsError',
            'InvalidTransferParamsError',
            'UnderMaintenanceError'
        ],
        ChangePasswordOutput: ['ChangePasswordSuccess', 'InvalidCredentialsError', 'InvalidPasswordError'],
        CheckPasswordOutput: ['CheckPasswordSuccess', 'InvalidCredentialsError'],
        ConfirmPasswordResetOutput: [
            'ConfirmPasswordResetSuccess',
            'InvalidJWTError',
            'InvalidPasswordError',
            'InvalidVerifyCodeError',
            'UserNotFoundError'
        ],
        ConnectByWalletOutput: ['AskForWalletSuccess', 'DeeplinkNotGeneratedError', 'InvalidParamsError'],
        CreateOfficeOutput: [
            'CreateOfficeSuccess',
            'InvalidParamsError',
            'OfficeNotFoundError',
            'UserIsInOtherOfficeError',
            'UserNotFoundError'
        ],
        CreateUserOutput: [
            'CreateUserSuccess',
            'InvalidCredentialsError',
            'InvalidEmailError',
            'InvalidParamsError',
            'OfficeNotFoundError',
            'UserAlreadyExistsError'
        ],
        DeleteOfficeOutput: ['DeleteOfficeSuccess', 'OfficeNotFoundError'],
        DeleteUserOutput: ['DeleteUserSuccess', 'UserNotFoundError'],
        MakeExchangeOutput: [
            'CurrencyNotFoundError',
            'ExchangeAmountTooHighError',
            'ExchangeAmountTooLowError',
            'ExchangePairNotFoundError',
            'ExchangePairNotPresentError',
            'InvalidParamsError',
            'InvalidTransferParamsError',
            'MakeExchangeSuccess',
            'UnderMaintenanceError'
        ],
        RefreshTokenOutput: ['RefreshTokenSuccess'],
        ResetPasswordOutput: ['NoResendAttemptsError', 'ResetPasswordSuccess', 'UserNotFoundError'],
        SigninOutput: ['InvalidCredentialsError', 'LoginIsEmptyError', 'SigninSuccess'],
        UpdateOfficeOutput: ['InvalidParamsError', 'OfficeNotFoundError', 'UpdateOfficeSuccess'],
        UpdatePairSettingsOutput: [
            'ExchangePairNotFoundError',
            'InvalidParamsError',
            'OfficeNotFoundError',
            'UpdatePairSettingsSuccess'
        ],
        UpdateUserOutput: [
            'InvalidCredentialsError',
            'InvalidEmailError',
            'InvalidParamsError',
            'UpdateUserSuccess',
            'UserAlreadyExistsError',
            'UserNotFoundError'
        ],
        VerifyCodeOutput: [
            'InvalidJWTError',
            'InvalidVerifyCodeError',
            'NoConfirmationAttemptsError',
            'UserAlreadyExistsError',
            'VerifyCodeExpiredError',
            'VerifyCodeSuccess'
        ],
        WalletConnectionUpdate: [
            'ConfirmationFailedError',
            'EosAccountAlreadyTakenError',
            'UserNotFoundError',
            'WalletConnectionSuccess',
            'WalletLinkOutdatedError'
        ]
    }
};
export default result;
