import React from 'react';
import { useMeQuery } from 'apollo/generated';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Button from 'ui/Button/Button';
import { ExitIcon, LocationFilledIcon } from 'ui/Icons/Icons';
import { useDialog } from 'ui/Dialog/Dialog';
import Preloader from 'ui/Preloader/Preloader';
import Logo from '../Logo/Logo';
import { AdminNavigationMobile } from '../Navigation/Navigation';
import LogoutModal from '../AuthLogoutModal/AuthLogoutModal';
import { AdminNavigation } from '../AdminNavigation/AdminNavigation';
import styles from './AdminHeader.module.scss';

interface AdminHeaderProps {
    isLimitedView?: boolean;
}

function AdminHeader({ isLimitedView }: AdminHeaderProps) {
    const { data, loading } = useMeQuery();
    const logoutModal = useDialog();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const { name, address, city } = data?.me?.office || {};

    return (
        <>
            <header className={styles.Root}>
                <div className={styles.Left}>
                    <Logo classes={{ root: styles.Logo }} />
                    {!isLimitedView && <AdminNavigation failedExchangesNumber={0} />}
                    {isMobileOrLess && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            isDark
                            onClick={() => logoutModal.setOpen(true)}
                            classes={{ root: styles.LogoutButton }}
                            iconStart={<ExitIcon className={styles.LogoutIcon} />}
                        />
                    )}
                </div>
                <div className={styles.Right}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        isDark
                        classes={{ root: styles.LocationButton }}
                        iconStart={<LocationFilledIcon className={styles.LocationButtonIcon} />}
                    >
                        {loading ? (
                            <Preloader />
                        ) : (
                            <span className={styles.Name}>{`${city}, ${name}, ${address}`}</span>
                        )}
                    </Button>
                    {!isMobileOrLess && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            isDark
                            onClick={() => logoutModal.setOpen(true)}
                            classes={{ root: styles.LogoutButton }}
                            iconStart={<ExitIcon className={styles.LogoutIcon} />}
                        />
                    )}
                </div>
            </header>
            {!isLimitedView && <AdminNavigationMobile />}
            <LogoutModal {...logoutModal} />
        </>
    );
}

export default AdminHeader;
