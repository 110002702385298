import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminHeader from '../AdminHeader/AdminHeader';

function AdminLayout() {
    return (
        <div>
            <AdminHeader />
            <main>
                <Outlet />
            </main>
        </div>
    );
}

export default AdminLayout;
