import React from 'react';
import cn from 'classnames';
import Logo from '../Logo/Logo';
import styles from './Auth.module.scss';

export default function Auth({ children }: React.PropsWithChildren) {
    return (
        <div className={cn(styles.Root)}>
            <div className={cn(styles.Header)}>
                <Logo theme="dark" />
            </div>
            <div className={cn(styles.Main)}>{children}</div>
        </div>
    );
}
