import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, CrossIcon, ShowOffIcon, ShowOnIcon } from 'ui/Icons/Icons';
import Tooltip, { useTooltip } from 'ui/Tooltip/Tooltip';
import TextField from 'ui/TextField/TextField';
import Button from 'ui/Button/Button';
import { TextFieldPropsAll } from 'ui/TextField/TextField.d';
import { FormFieldPasswordProps } from './FormFieldPassword.d';
import styles from './FormFieldPassword.module.scss';

const FormFieldPassword = React.forwardRef<
    HTMLInputElement,
    React.PropsWithChildren<TextFieldPropsAll & FormFieldPasswordProps>
>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function FormFieldPasswordComponent({ hideShowButton, validatePassword, ...InputProps }, ref) {
        const [t] = useTranslation();
        const tooltip = useTooltip({ placement: 'right' });

        const [showPassword, setShowPassword] = React.useState(false);

        const handleToggleShowPassword = (show: boolean) => () => setShowPassword(show);

        const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            InputProps.onFocus?.(e);

            if (validatePassword) tooltip.setOpen(true);
        };

        const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            InputProps.onBlur?.(e);

            if (validatePassword) tooltip.setOpen(false);
        };

        return validatePassword ? (
            <Tooltip
                {...tooltip}
                content={
                    <div>
                        <p className={styles.TooltipText}>
                            {validatePassword.length ? (
                                <CheckIcon className={styles.IconCheck} />
                            ) : (
                                <CrossIcon className={styles.IconClose} />
                            )}{' '}
                            {t('passwordRules.length')}
                        </p>
                        <p className={styles.TooltipText}>
                            {validatePassword.hasUppercase ? (
                                <CheckIcon className={styles.IconCheck} />
                            ) : (
                                <CrossIcon className={styles.IconClose} />
                            )}{' '}
                            {t('passwordRules.uppercase')}
                        </p>
                        <p className={styles.TooltipText}>
                            {validatePassword.hasNumber ? (
                                <CheckIcon className={styles.IconCheck} />
                            ) : (
                                <CrossIcon className={styles.IconClose} />
                            )}{' '}
                            {t('passwordRules.number')}
                        </p>
                        <p className={styles.TooltipText}>
                            {validatePassword.hasSpecial ? (
                                <CheckIcon className={styles.IconCheck} />
                            ) : (
                                <CrossIcon className={styles.IconClose} />
                            )}{' '}
                            {t('passwordRules.special')}
                        </p>
                    </div>
                }
            >
                <TextField
                    label="Пароль"
                    placeholder="Введите пароль"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    type={showPassword ? 'text' : 'password'}
                    adornmentEnd={
                        !hideShowButton && (
                            <Button
                                type="button"
                                variant="text"
                                color="gray"
                                isRound
                                size="small"
                                iconStart={showPassword ? <ShowOffIcon /> : <ShowOnIcon />}
                                onClick={handleToggleShowPassword(!showPassword)}
                            />
                        )
                    }
                    maxLength={64}
                    ref={ref}
                    {...InputProps}
                />
            </Tooltip>
        ) : (
            <TextField
                label="Пароль"
                placeholder="Введите пароль"
                onFocus={handleFocus}
                onBlur={handleBlur}
                type={showPassword ? 'text' : 'password'}
                adornmentEnd={
                    !hideShowButton && (
                        <Button
                            type="button"
                            variant="text"
                            color="gray"
                            isRound
                            size="small"
                            iconStart={showPassword ? <ShowOffIcon /> : <ShowOnIcon />}
                            onClick={handleToggleShowPassword(!showPassword)}
                        />
                    )
                }
                maxLength={64}
                ref={ref}
                {...InputProps}
            />
        );
    }
);

export default FormFieldPassword;
