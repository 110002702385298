import React from 'react';

interface TableOfficeCellProps {
    address?: string | null;
    city?: string | null;
    length?: number;
}

const sliceAddress = (value: string, length: number) =>
    value?.length > length ? `${value.slice(0, length)}...` : value;

export default function TableOfficeCell({ city, address, length = 50 }: TableOfficeCellProps) {
    if (city && address) return <>{sliceAddress(`${city}, ${address}`, length)}</>;
    if (city && !address) return <>{sliceAddress(city, length)}</>;
    if (!city && address) return <>{sliceAddress(address, length)}</>;

    return '-';
}
