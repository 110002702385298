import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import {
    isAdminVar,
    isAuthVar,
    isManagerVar,
    showModalSessionVar,
    LOCAL_STORAGE_KEY_TOKEN_AUTH,
    LOCAL_STORAGE_KEY_IS_ADMIN,
    LOCAL_STORAGE_KEY_IS_MANAGER,
    LOCAL_STORAGE_KEY_OFFICE
} from 'apollo/cache';
import { URLS_MAP } from 'routes/routes';
import setOffice from '../Office/Office';

export const admin = (bool: boolean) => {
    isAdminVar(bool);

    if (bool) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY_IS_ADMIN, '1');
    }
};

export const manager = (bool: boolean) => {
    isManagerVar(bool);

    if (bool) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY_IS_MANAGER, '1');
    }
};

export const login = (token: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN_AUTH, token);
    isAuthVar(true);
};

export const resetStorageKeys = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_IS_ADMIN);
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_IS_MANAGER);
    admin(false);
    manager(false);
};

export const logout = (withShowSessionModal?: boolean) => {
    if (withShowSessionModal) showModalSessionVar(true);

    window.localStorage.removeItem(LOCAL_STORAGE_KEY_OFFICE);
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_IS_ADMIN);
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_IS_MANAGER);
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);
    admin(false);
    manager(false);
    setOffice('');
    isAuthVar(false);
};

export function RequireAuth() {
    const location = useLocation();
    const isAuth = useReactiveVar(isAuthVar);

    return isAuth ? <Outlet /> : <Navigate to={{ pathname: URLS_MAP.login }} state={{ from: location }} replace />;
}

export function RequireAdmin() {
    const location = useLocation();
    const isAdmin = useReactiveVar(isAdminVar);

    return isAdmin ? <Outlet /> : <Navigate to={URLS_MAP.root} state={{ from: location }} replace />;
}

export function RequireManager() {
    const location = useLocation();
    const isManager = useReactiveVar(isManagerVar);

    return isManager ? <Outlet /> : <Navigate to={URLS_MAP.root} state={{ from: location }} replace />;
}

export function RequireSuperAdmin() {
    const location = useLocation();
    const isAdmin = useReactiveVar(isAdminVar);
    const isManager = useReactiveVar(isManagerVar);

    return !isManager && !isAdmin ? <Outlet /> : <Navigate to={URLS_MAP.root} state={{ from: location }} replace />;
}
