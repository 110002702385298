import React from 'react';
import cn from 'classnames';
import { LOCAL_STORAGE_KEY_TOKEN_AUTH } from 'apollo/cache';
import { UserRole, useSignInMutation } from 'apollo/generated';
import removeSpaces from 'utils/removeSpaces/removeSpaces';
import { DRAG_ANIMATION_DURATION } from 'helpers/CONSTANTS_DEPRECATED';
import validator from 'helpers/validator';
import { resetStorageKeys } from 'helpers/Auth/Auth';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useLoginValidation from 'hooks/useLoginValidation/useLoginValidation';
import useGlobalError from 'hooks/useGlobalError/useGlobalError';
import useFormField from 'hooks/useFormField/useFormField';
import useOffice from 'hooks/useOffice/useOffice';
import useAuth from 'hooks/useAuth/useAuth';
import { useToast } from 'ui/Toast/Toast';
import Button from 'ui/Button/Button';
import TextField from 'ui/TextField/TextField';
import FormFieldPassword from '../FormFieldPassword/FormFieldPassword';
import styles from './AuthLogin.module.scss';

export default function AuthLogin() {
    const onGlobalError = useGlobalError();
    const toast = useToast();
    const { login } = useAuth();
    const { setOffice } = useOffice();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);

    const loginInput = useFormField('');
    const passwordInput = useFormField('');

    const [passwordError, setPasswordError] = React.useState(false);
    const formRef = React.useRef<HTMLFormElement>(null);

    const { isValid: loginIsValid, errorText: loginErrorText } = useLoginValidation(loginInput.value);

    const [signinMutation, { loading }] = useSignInMutation();

    /** Await finishing of drag animation */
    const onErrorForm = () => {
        const className = cn('DragAnimation');

        formRef.current?.classList.remove(className);
        formRef.current?.classList.add(className);
        setTimeout(() => formRef.current?.classList.remove(className), DRAG_ANIMATION_DURATION);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        loginInput.errorChange(false, '');
        passwordInput.errorChange(false, '');

        if (typeof window !== 'undefined' && window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH)) {
            window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);
        }

        if (!loginInput.value || !passwordInput.value) {
            onErrorForm();

            return toast.error('Для продолжения заполните все обязательные поля');
        }

        if (!loginIsValid && !isMobileOrLess) {
            return loginInput.errorChange(true, loginErrorText);
        }

        signinMutation({
            variables: {
                email: loginInput.value.includes('@') ? removeSpaces(loginInput.value) : '',
                password: passwordInput.value
            }
        })
            .then(({ data }) => {
                const signin = data?.signin;
                if (signin) {
                    switch (signin.__typename) {
                        case 'SigninSuccess': {
                            const { token, me } = signin;
                            const { role } = me ?? {};

                            resetStorageKeys();

                            const isAdmin = role === UserRole.Administrator;
                            const isManager = role === UserRole.Cashier;

                            if ((isAdmin || isManager) && me?.office?.id) {
                                setOffice(`${me.office.id}`);
                            } else {
                                setOffice('all');
                            }

                            login({ token, setIsManager: isManager, setIsAdmin: isAdmin });
                            break;
                        }
                        case 'InvalidCredentialsError': {
                            onErrorForm();
                            loginInput.errorChange(true, ' ');
                            passwordInput.errorChange(true, 'Вы ввели неверный логин или пароль');
                            break;
                        }
                        default: {
                            onGlobalError('Что то пошло не так', toast);
                        }
                    }
                } else {
                    onGlobalError('Неудачная авторизация', toast);
                }
            })
            .catch(async () => {
                setPasswordError(true);
                onErrorForm();
            });

        return undefined;
    };

    return (
        <div className={styles.Root}>
            <div className={styles.FormWrapper}>
                <h4>Вход</h4>
                <form
                    style={{ animationDuration: `${DRAG_ANIMATION_DURATION}ms` }}
                    ref={formRef}
                    onSubmit={handleSubmit}
                >
                    <TextField
                        classes={{ root: styles.FormLoginInput }}
                        label="Почта"
                        placeholder="Введите почту"
                        value={loginInput.value}
                        isError={Boolean(loginInput.error)}
                        helperText={loginInput.error}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            passwordInput.errorChange(false, '');
                            loginInput?.change(validator(e, e.target.value));
                        }}
                        autoComplete="username"
                        required
                    />
                    <FormFieldPassword
                        classes={{ root: styles.FormPasswordInput }}
                        value={passwordInput.value}
                        isError={Boolean(passwordInput.error) || passwordError}
                        helperText={passwordInput.helperText}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            loginInput.errorChange(false, '');
                            passwordInput?.change(validator(e, e.target.value));
                        }}
                        onFocus={() => setPasswordError(false)}
                        autoComplete="current-password"
                        required
                    />
                    {passwordError && <span className={styles.PasswordError}>Вы ввели неверную почту или пароль</span>}
                    <Button
                        classes={{ root: styles.FormSubmitButton }}
                        loading={loading}
                        disabled={!loginInput.value || !passwordInput.value || passwordError}
                        size="large"
                        fullWidth
                        type="submit"
                    >
                        Войти в аккаунт
                    </Button>
                </form>
            </div>
        </div>
    );
}
