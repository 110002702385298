import { InMemoryCache, ReactiveVar, makeVar } from '@apollo/client';
import generated from './generated';
import { getExpirationDelta, OFFSET } from '../helpers/IdleController/IdleController';

export const LOCAL_STORAGE_KEY_TOKEN_AUTH = 'tokenAuth';
export const LOCAL_STORAGE_KEY_IS_ADMIN = 'isAdmin';
export const LOCAL_STORAGE_KEY_IS_MANAGER = 'isManager';
export const LOCAL_STORAGE_KEY_OFFICE = 'office';
export const LOCAL_STORAGE_TRANSACTION_ID = 'transaction';

const isAuth = Boolean(getExpirationDelta() - OFFSET > 0);
const isAdmin = typeof window !== 'undefined' ? window.localStorage.getItem(LOCAL_STORAGE_KEY_IS_ADMIN) : null;
const isManager = typeof window !== 'undefined' ? window.localStorage.getItem(LOCAL_STORAGE_KEY_IS_MANAGER) : null;
const office = typeof window !== 'undefined' ? window.localStorage.getItem(LOCAL_STORAGE_KEY_OFFICE) : null;

export const officeVar: ReactiveVar<string> = makeVar(office ?? '');
export const isAuthVar: ReactiveVar<boolean> = makeVar(Boolean(isAuth));
export const isAdminVar: ReactiveVar<boolean> = makeVar(Boolean(isAdmin));
export const isManagerVar: ReactiveVar<boolean> = makeVar(Boolean(isManager));
export const showModalSessionVar: ReactiveVar<boolean> = makeVar(false);
export const errorVar: ReactiveVar<any> = makeVar(null);

export const cache: InMemoryCache = new InMemoryCache({
    ...generated,
    typePolicies: {
        Query: {
            fields: {
                office: {
                    read() {
                        return officeVar();
                    }
                },
                isAuth: {
                    read() {
                        return isAuthVar();
                    }
                },
                isAdmin: {
                    read() {
                        return isAdminVar();
                    }
                },
                isManager: {
                    read() {
                        return isManagerVar();
                    }
                },
                showModalSession: {
                    read() {
                        return showModalSessionVar();
                    }
                },
                error: {
                    read() {
                        return errorVar();
                    }
                }
            }
        }
    }
});
