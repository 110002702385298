import React, { useEffect, useState } from 'react';
import { useOfficesQuery } from 'apollo/generated';
import { ExitIcon, LocationFilledIcon } from 'ui/Icons/Icons';
import useDropdown from 'hooks/useDropdown/useDropdown';
import useOffice from 'hooks/useOffice/useOffice';
import { setCheckedMark } from 'helpers/dropdownImprovements/dropdownImprovements';
import Dropdown from 'ui/Dropdown/Dropdown';
import { useDialog } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import { DropdownOptions } from 'ui/Dropdown/Dropdown.d';
import { Navigation, NavigationMobile } from '../Navigation/Navigation';
import LogoutModal from '../AuthLogoutModal/AuthLogoutModal';
import Logo from '../Logo/Logo';
import TableOfficeCell from '../TableOfficeCell/TableOfficeCell';
import { HeaderProps } from './Header.d';
import styles from './Header.module.scss';
import useMediaQuery from '../../hooks/useMediaQuery/useMediaQuery';

export default function Header({ isLimitedView, LogoProps }: HeaderProps) {
    const logoutModal = useDialog();
    const dropdown = useDropdown();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions[]>([]);

    const { data } = useOfficesQuery();

    const { office: currentOffice, setOffice } = useOffice();

    const handleDropdownChange = (value: string) => {
        setOffice(value);
    };

    const handleDropdown = (value: string) => {
        const dropdownItems = dropdownOptions;

        dropdownItems.forEach((el) => {
            const option = el;

            option.isSelected = option.value === value;
        });

        setDropdownOptions(() => [...dropdownItems]);
        handleDropdownChange(value);
    };

    const getDefaultLabel = () =>
        currentOffice !== 'all' ? dropdownOptions.find((item) => item.value === currentOffice)?.label : 'Все точки';

    useEffect(() => {
        if (data?.offices?.offices.length) {
            const dropdownItems =
                data.offices.offices.map((office) => ({
                    value: `${office?.id}`,
                    label: (
                        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            <TableOfficeCell
                                length={isMobileOrLess ? 40 : 20}
                                address={office?.address}
                                city={office?.city}
                            />
                        </span>
                    ),
                    isSelected: currentOffice === `${office?.id}`
                })) ?? [];

            setDropdownOptions(dropdownItems);
        }
    }, [isMobileOrLess, currentOffice, data]);

    return (
        <>
            <header className={styles.Root}>
                <div className={styles.Left}>
                    <Logo {...LogoProps} classes={{ root: styles.Logo }} />
                    {!isLimitedView && <Navigation />}
                    {isMobileOrLess && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            isDark
                            onClick={() => logoutModal.setOpen(true)}
                            classes={{ root: styles.LogoutButton }}
                            iconStart={<ExitIcon className={styles.LogoutIcon} />}
                        />
                    )}
                </div>
                <div className={styles.Right}>
                    <Dropdown
                        controllerLabel={getDefaultLabel()}
                        controllerLabelDefault={getDefaultLabel()}
                        options={setCheckedMark(
                            [
                                {
                                    label: 'Все точки',
                                    value: 'all',
                                    isSelected: currentOffice === 'all'
                                },
                                ...dropdownOptions
                            ],
                            currentOffice
                        )}
                        open={dropdown.open}
                        onChange={handleDropdown}
                        onOpenChange={dropdown.setOpen}
                        ControllerProps={{
                            iconStart: <LocationFilledIcon className={styles.DropdownIcon} />,
                            isDark: isMobileOrLess,
                            color: isMobileOrLess ? 'secondary' : 'gray',
                            variant: isMobileOrLess ? 'outlined' : 'contained'
                        }}
                        classes={{ controller: isMobileOrLess ? styles.Dropdown : styles.DropdownController }}
                    />
                    {!isMobileOrLess && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            isDark
                            onClick={() => logoutModal.setOpen(true)}
                            classes={{ root: styles.LogoutButton }}
                            iconStart={<ExitIcon className={styles.LogoutIcon} />}
                        />
                    )}
                </div>
            </header>
            {!isLimitedView && <NavigationMobile />}
            <LogoutModal {...logoutModal} />
        </>
    );
}
