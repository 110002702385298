import React from 'react';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import PaymentCancellation from '../PaymentCancellation/PaymentCancellation';

export default function PaymentCancellationModal({
    open,
    setOpen,
    context,
    ...props
}: ReturnType<typeof useDialog> & {
    onClose?: () => void;
}) {
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onOpenChange={handleClose}
            fullScreen={isMobileOrLess}
            fullWidth={false}
            maxWidth="sm"
            transition={isMobileOrLess ? 'slide-left' : 'slide-up'}
            {...props}
        >
            <PaymentCancellation onClose={handleClose} />
        </Dialog>
    );
}
