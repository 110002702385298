import React from 'react';
import detectOS from 'utils/detectOS/detectOS';

export default function FeaturesDetection() {
    React.useEffect(() => {
        const { documentElement } = document;
        const os = detectOS();

        if (documentElement && os) {
            documentElement.classList.add(os);
        }
    }, []);

    return null;
}
