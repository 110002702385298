import React from 'react';
import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import Button from 'ui/Button/Button';
import { DashboardFilledIcon, DocFilledIcon, LocationIcon, ManagersIcon } from 'ui/Icons/Icons';
import styles from './Navigation.module.scss';

export function Navigation() {
    const { pathname } = useLocation();

    return (
        <nav className={styles.Nav}>
            <ul className={styles.NavList}>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root:
                                pathname === URLS_MAP.dashboard
                                    ? cn(styles.NavLink, styles.NavLinkActive)
                                    : styles.NavLink
                        }}
                        to={URLS_MAP.dashboard}
                        end
                        iconStart={<DashboardFilledIcon />}
                    >
                        Дашборд
                    </Button>
                </li>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root:
                                pathname === URLS_MAP.transactions
                                    ? cn(styles.NavLink, styles.NavLinkActive)
                                    : styles.NavLink
                        }}
                        to={URLS_MAP.transactions}
                        iconStart={<DocFilledIcon />}
                        // iconEnd={
                        //     failedExchangesNumber ? (
                        //         <span className={styles.NavLinkBadge}>{failedExchangesNumber}</span>
                        //     ) : undefined
                        // }
                    >
                        Транзакции
                    </Button>
                </li>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root:
                                pathname === URLS_MAP.offices
                                    ? cn(styles.NavLink, styles.NavLinkActive)
                                    : styles.NavLink
                        }}
                        to={URLS_MAP.offices}
                        iconStart={<LocationIcon />}
                    >
                        Точки
                    </Button>
                </li>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root: pathname === URLS_MAP.list ? cn(styles.NavLink, styles.NavLinkActive) : styles.NavLink
                        }}
                        to={URLS_MAP.list}
                        iconStart={<ManagersIcon />}
                    >
                        Пользователи
                    </Button>
                </li>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root: pathname === URLS_MAP.logs ? cn(styles.NavLink, styles.NavLinkActive) : styles.NavLink
                        }}
                        to={URLS_MAP.logs}
                        iconStart={<DocFilledIcon />}
                    >
                        Логи
                    </Button>
                </li>
            </ul>
        </nav>
    );
}

export function NavigationMobile() {
    const { pathname } = useLocation();

    return (
        <div className={cn(styles.Bar)}>
            <nav className={cn(styles.BarNav)}>
                <ul className={cn(styles.BarNavList)}>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.dashboard
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.dashboard}
                            end
                            fullWidth
                            iconStart={<DashboardFilledIcon />}
                        >
                            Дашборд
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.transactions
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.transactions}
                            end
                            fullWidth
                            iconStart={<DocFilledIcon />}
                        >
                            Транзакции
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.offices
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.offices}
                            end
                            fullWidth
                            iconStart={<LocationIcon />}
                        >
                            Точки
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.list
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.list}
                            end
                            fullWidth
                            iconStart={<ManagersIcon />}
                        >
                            Пользователи
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.logs
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.logs}
                            end
                            fullWidth
                            iconStart={<DocFilledIcon />}
                        >
                            Логи
                        </Button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export function AdminNavigationMobile() {
    const { pathname } = useLocation();

    return (
        <div className={cn(styles.Bar)}>
            <nav className={cn(styles.BarNav)}>
                <ul className={cn(styles.BarNavList)}>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.admin.dashboard
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.admin.dashboard}
                            end
                            fullWidth
                            iconStart={<DashboardFilledIcon />}
                        >
                            Дашборд
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.admin.transactions
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.admin.transactions}
                            end
                            fullWidth
                            iconStart={<DocFilledIcon />}
                        >
                            Транзакции
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.admin.managers
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.admin.managers}
                            end
                            fullWidth
                            iconStart={<ManagersIcon />}
                        >
                            Менеджеры
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.admin.logs
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.admin.logs}
                            end
                            fullWidth
                            iconStart={<DocFilledIcon />}
                        >
                            Логи
                        </Button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
