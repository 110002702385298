import pageSign from './pageSign';
import modalLogout from './modalLogout';
import modalProfile from './modalProfile';
import managerPage from './managerPage';
import transactions from './transactions';
import modalSession from './modalSession';
import logs from './logs';

export default {
    managerPage,
    modalLogout,
    modalProfile,
    modalSession,
    transactions,
    logs,
    pageSign,

    pageDevelopment: {
        title: 'В разработке',
        description: 'Данный раздел находится\nв разработке'
    },

    noDataStub: {
        loadingFailed: 'Во время загрузки произошла ошибка',
        update: 'Обновить'
    },

    errors: {
        globalError: 'Произошла ошибка. Попробуйте позже',
        globalErrorTryMore: 'Произошла непредвиденная ошибка.\nПопробуйте еще раз.',
        global: {
            copied: 'Данные скопированы',

            fee: 'Комиссия',

            btnReset: 'Сбросить',
            defaultStoreChanged: 'Выбран магазин “{{name}}”',

            units: {
                hours: 'час',
                hours_short: 'ч.',
                minutes: 'мин',
                minutes_short: 'м.'
            }
        }
    },
    admin: {
        dashboard: {
            title: 'Дашборд'
        },
        transactions: {
            title: 'Транзакции'
        },
        managers: {
            title: 'Менеджеры'
        },
        logs: {
            title: 'Логи'
        },
        role: {
            ADMINISTRATOR: 'Администратор',
            CASHIER: 'Менеджер',
            OWNER: 'Супер Администратор'
        }
    }
};
