import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth/useAuth';
import Dialog, { DialogHeader, DialogFooter, useDialog, DialogBody } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import styles from './AuthLogoutModal.module.scss';
import useMediaQuery from '../../hooks/useMediaQuery/useMediaQuery';

export default function ModalLogout({
    open,
    setOpen,
    onClose,
    ...props
}: ReturnType<typeof useDialog> & {
    onClose?: () => void;
}) {
    const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);
    const [t] = useTranslation();
    const { logout } = useAuth();

    const handleClose = () => {
        setOpen(false);
    };

    const onClickLogout = () => {
        logout();
        handleClose();
    };

    return (
        <Dialog open={open} onOpenChange={setOpen} fullScreen={isTabletOrLess} {...props} onUnmount={onClose}>
            <DialogHeader title={t('modalLogout.title')} />
            <DialogBody>
                <div className={styles.Text}>{t('modalLogout.description')}</div>
            </DialogBody>
            <DialogFooter>
                <Button
                    fullWidth
                    color="gray"
                    size="large"
                    type="button"
                    onClick={onClickLogout}
                    style={{ marginBottom: '0.5rem' }}
                >
                    {t('modalLogout.btnSubmit')}
                </Button>
                <Button type="button" onClick={handleClose} size="large" fullWidth>
                    {t('modalLogout.btnCancel')}
                </Button>
            </DialogFooter>
        </Dialog>
    );
}
