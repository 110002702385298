import React from 'react';
import { useTranslation } from 'react-i18next';
import DevelopmentImage from 'assets/images/development.svg';
import styles from './UnderConstruction.module.scss';

export default function UnderConstruction() {
    const [t] = useTranslation();
    return (
        <div className={styles.Component}>
            <img src={DevelopmentImage} alt="" />
            <h1>{t('pageDevelopment.title')}</h1>
            <p>{t('pageDevelopment.description')}</p>
        </div>
    );
}
