import React from 'react';
import jwt_decode from 'jwt-decode';
import { useIdleTimer } from 'react-idle-timer';
import { reconnectWS } from 'apollo';
import { LOCAL_STORAGE_KEY_TOKEN_AUTH } from 'apollo/cache';
import { useRefreshTokenMutation } from 'apollo/generated';
import useAuth from 'hooks/useAuth/useAuth';
import { login } from '../Auth/Auth';

export const getToken = () => localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);

const getExpirationTimestamp = () => {
    const token = getToken();

    try {
        const jwtToken = jwt_decode(token ?? '') as { [key: string]: any };
        /** timestamp in sec */
        return jwtToken?.expiration ?? 0;
    } catch {
        return 0;
    }
};

export const getExpirationDelta = () => getExpirationTimestamp() * 1000 - Number(new Date()); // 30 * 60 * 1000

/** 1000 * 60 = 1 minute */
export const OFFSET = 20 * 60 * 1000;
const ACTIVITY_TIMEOUT = 0.1 * 60 * 1000;

export default function IdleController() {
    const { isAuth } = useAuth();
    const [refreshTokenMutation] = useRefreshTokenMutation();

    const [state, setState] = React.useState<'Active' | 'Idle'>('Active');

    useIdleTimer({
        onIdle: () => setState('Idle'),
        onActive: () => setState('Active'),
        timeout: ACTIVITY_TIMEOUT,
        throttle: 500
    });

    React.useEffect(() => {
        if (isAuth && state === 'Active' && getExpirationDelta() - OFFSET < 0) {
            refreshTokenMutation()
                .then(({ data }) => {
                    login(data?.refreshToken?.token ?? '');
                    reconnectWS();
                })
                .catch(() => {
                    // eslint-disable-next-line no-console
                    console.log('Token refresh error');
                });
        }
    }, [isAuth, refreshTokenMutation, state]);

    return null;
}
