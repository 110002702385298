import React from 'react';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import { useDialog } from 'ui/Dialog/Dialog';
import { URLS_MAP } from 'routes/routes';
import LogoutModal from '../AuthLogoutModal/AuthLogoutModal';
import {
    ManagerMobileInfo,
    ManagerNavigation,
    ManagerNavigationLogOut,
    ManagerNavigationMobile
} from '../ManagerNavigation/ManagerNavigation';
import Logo from '../Logo/Logo';
import PaymentCancellationModal from '../PaymentCancellationModal/PaymentCancellationModal';
import { ManagerHeaderProps } from './ManagerHeader.d';
import styles from './ManagerHeader.module.scss';

export default function ManagerHeader({ address, email, isLimitedView, isPayment, LogoProps }: ManagerHeaderProps) {
    const logoutModal = useDialog();
    const closeModal = useDialog();
    const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.md);

    const handleCloseClick = () => closeModal.setOpen(true);

    return (
        <>
            {!isPayment && isTabletOrLess && (
                <header className={styles.MainRootMobile}>
                    <div>
                        <Logo onClick={handleCloseClick} {...LogoProps} classes={{ root: styles.Logo }} />
                    </div>
                    <div className={styles.UserInfo}>
                        <ManagerMobileInfo email={email} address={address} logout={logoutModal.setOpen} />
                    </div>
                </header>
            )}
            {!isPayment && !isTabletOrLess && (
                <header className={styles.MainRoot}>
                    <div>
                        <Logo {...LogoProps} classes={{ root: styles.Logo }} href={URLS_MAP.manager.index} />
                        <ManagerNavigation />
                    </div>
                    <div>
                        <ManagerNavigationLogOut
                            address={address}
                            email={email || 'user'}
                            logout={logoutModal.setOpen}
                        />
                    </div>
                </header>
            )}
            {!isLimitedView && isTabletOrLess && <ManagerNavigationMobile />}
            <LogoutModal {...logoutModal} />
            <PaymentCancellationModal {...closeModal} />
        </>
    );
}
