import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { isAdminVar, isAuthVar, isManagerVar } from 'apollo/cache';
import client from 'apollo';
import { admin, login, logout, manager } from 'helpers/Auth/Auth';
import { URLS_MAP } from 'routes/routes';

interface Login {
    token: string;
    setIsManager?: boolean;
    setIsAdmin?: boolean;
}

/**
 * Authentication hook.
 *
 * @returns {{
 *     isAuth: boolean,
 *     isAdmin: boolean,
 *     isManager: boolean,
 *     logout: (withShowSessionModal?: boolean) => void,
 *     login: ({
 *                 token,
 *                 setIsManager,
 *                 setIsAdmin
 *             }:Login ) => void,
 *     setManager: (setIsManager: boolean) => void
 * }}
 */
export default function useAuth(): {
    isAuth: boolean;
    isAdmin: boolean;
    isManager: boolean;
    logout: (withShowSessionModal?: boolean) => void;
    login: ({ token, setIsManager, setIsAdmin }: Login) => void;
    setManager: (setIsManager: boolean) => void;
    setAdmin: (setIsAdmin: boolean) => void;
} {
    const navigate = useNavigate();
    const isAuth = useReactiveVar(isAuthVar);
    const isAdmin = useReactiveVar(isAdminVar);
    const isManager = useReactiveVar(isManagerVar);

    return {
        isAuth,
        isAdmin,
        isManager,
        login: ({ token, setIsManager, setIsAdmin }: Login) => {
            login(token);
            if (setIsManager) {
                manager(true);
                navigate(URLS_MAP.manager.index, { replace: true });
            } else if (setIsAdmin) {
                admin(true);
                navigate(URLS_MAP.admin.dashboard, { replace: true });
            } else {
                navigate(URLS_MAP.root, { replace: true });
            }
        },
        setManager: (setIsManager: boolean) => {
            manager(setIsManager);
        },
        setAdmin: (setIsAdmin: boolean) => {
            admin(setIsAdmin);
        },
        logout: (withShowSessionModal?: boolean) => {
            logout(withShowSessionModal);
            client.cache.reset().then(() => navigate(URLS_MAP.login, { replace: true }));
        }
    };
}
