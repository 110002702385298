import { useReactiveVar } from '@apollo/client';
import { officeVar } from 'apollo/cache';
import setOffice from 'helpers/Office/Office';

/**
 * Authentication hook.
 *
 * @returns {{
 *    office: string;
 *    setOffice: (setOfficeId: string) => void;
 * }}
 */
export default function useOffice(): {
    office: string;
    setOffice: (setOfficeId: string) => void;
} {
    const office = useReactiveVar(officeVar);

    return {
        office,
        setOffice: (setOfficeId: string) => {
            setOffice(setOfficeId);
        }
    };
}
