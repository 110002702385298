import React from 'react';
import Auth from 'components/Auth/Auth';
import AuthLogin from 'components/AuthLogin/AuthLogin';

export default function LoginPage() {
    return (
        <Auth>
            <AuthLogin />
        </Auth>
    );
}
