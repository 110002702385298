import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import { useMeQuery } from 'apollo/generated';
import Preloader from 'ui/Preloader/Preloader';
import Header from '../Header/Header';
import styles from './Layout.module.scss';

export default function Layout() {
    const { pathname } = useLocation();

    const { data, loading } = useMeQuery({ fetchPolicy: 'network-only' });
    const { me } = data ?? {};

    if (loading || (data && !me)) {
        return (
            <div className={styles.Preloader}>
                <Preloader />
            </div>
        );
    }

    return (
        <>
            <Header LogoProps={{ disabled: pathname === URLS_MAP.dashboard }} />
            <main>
                <Outlet />
            </main>
        </>
    );
}
