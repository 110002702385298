import React from 'react';

const BREAKPOINTS = {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '1024px',
    xl: '1260px',
    xxl: '1420px'
};

type MediaQueries = {
    up: { [key in keyof typeof BREAKPOINTS]: string };
    down: { [key in keyof typeof BREAKPOINTS]: string };
};

function createBreakpoints(breakpoints: typeof BREAKPOINTS): MediaQueries {
    const breakpointKeys = Object.keys(breakpoints) as Array<keyof typeof BREAKPOINTS>;

    const up = breakpointKeys.reduce(
        (acc, key) => {
            acc[key] = `(min-width: ${breakpoints[key]})`;

            return acc;
        },
        {} as MediaQueries['up']
    );

    const down = breakpointKeys.reduce(
        (acc, key) => {
            acc[key] = `(max-width: ${breakpoints[key]})`;

            return acc;
        },
        {} as MediaQueries['down']
    );

    return { up, down };
}

export const breakpoints: MediaQueries = createBreakpoints(BREAKPOINTS);

/**
 * Media query hook.
 *
 * @param {(breakpoints: MediaQueries) => string} queryFunction
 *
 * @returns {boolean}
 *
 * @description { xs: '0', sm: '576px', md: '768px', lg: '1024px', xl: '1260px', xxl: '1420px' }.
 */
export default function useMediaQuery(queryFunction: (breakpoints: MediaQueries) => string): boolean {
    const query = queryFunction(breakpoints);

    const getMatches = (q: string): boolean => {
        /** Prevents SSR issues */
        if (typeof window !== 'undefined') {
            return window.matchMedia(q).matches;
        }

        return false;
    };

    const [matches, setMatches] = React.useState<boolean>(getMatches(query));

    const handleChange = React.useCallback(() => setMatches(getMatches(query)), [query]);

    React.useEffect(() => {
        const matchMedia = window.matchMedia(query);
        /** Triggered at the first client-side load and if query changes */
        handleChange();
        /** Listen to 'change' event */
        matchMedia.addEventListener('change', handleChange);

        return () => matchMedia.removeEventListener('change', handleChange);
    }, [handleChange, query]);

    return matches;
}
