import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import IconLogoDark from 'assets/images/logo-dark.svg';
import IconLogoLight from 'assets/images/logo-light.svg';
import { LogoProps } from './Logo.d';
import styles from './Logo.module.scss';

function LogoIcon(theme: LogoProps['theme']) {
    switch (theme) {
        case 'light':
            return IconLogoLight;
        case 'dark':
            return IconLogoDark;
        default:
            return IconLogoDark;
    }
}

export default function Logo({ classes, disabled, href = URLS_MAP.dashboard, theme = 'light', onClick }: LogoProps) {
    const { root, img } = classes ?? {};

    return onClick ? (
        <button
            type="button"
            disabled={disabled}
            className={cn(styles.Root, disabled && styles.RootDisabled, root)}
            onClick={onClick}
        >
            <img className={cn(styles.Img, img)} src={LogoIcon(theme)} alt="Logo" />
        </button>
    ) : (
        <Link className={cn(styles.Root, disabled && styles.RootDisabled, root)} to={href}>
            <img className={cn(styles.Img, img)} src={LogoIcon(theme)} alt="Logo" />
        </Link>
    );
}
