import React from 'react';
import { Navigate } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import { useMeQuery, UserRole } from 'apollo/generated';
import Preloader from 'ui/Preloader/Preloader';
import useAuth from 'hooks/useAuth/useAuth';
import styles from './StartTour.module.scss';

export default function StartTour() {
    const { setManager, setAdmin } = useAuth();

    const { data, loading } = useMeQuery({ fetchPolicy: 'network-only' });
    const me = data?.me;
    const { role } = me ?? {};

    const isManager = role === UserRole.Cashier;
    const isAdmin = role === UserRole.Administrator;

    if (!data || loading)
        return (
            <div className={styles.Preloader}>
                <Preloader />
            </div>
        );

    if (isManager) {
        setManager(true);
        return <Navigate to={URLS_MAP.manager.index} replace />;
    }

    if (isAdmin) {
        setAdmin(true);
        return <Navigate to={URLS_MAP.admin.index} replace />;
    }

    return <Navigate to={URLS_MAP.dashboard} />;
}
