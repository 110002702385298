import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import client from 'apollo';
import { ApolloProvider } from '@apollo/client';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import ErrorBoundary from './helpers/ErrorBoundary';
import IdleController from './helpers/IdleController/IdleController';
import FeaturesDetection from './helpers/FeaturesDetection/FeaturesDetection';
import ScrollToTopController from './helpers/ScrollToTopController/ScrollToTopController';
import i18n from './i18n';
import Routes from './routes/routes';
import ToastContainer from './ui/Toast/Toast';
import AuthSessionModal from './components/AuthSessionModal/AuthSessionModal';
import 'styles/main.scss';
// import reportWebVitals from './reportWebVitals';

/** Initialize language configs */
i18n();

/** Enable sentry only for production */
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0
    });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <ErrorBoundary /* fallback={<div>Error</div>} */>
                <BrowserRouter>
                    <IdleController />
                    <FeaturesDetection />
                    <ScrollToTopController />
                    <Routes />
                    <ToastContainer />
                    <AuthSessionModal />
                </BrowserRouter>
            </ErrorBoundary>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
