import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import Button from 'ui/Button/Button';
import { CrownFilledIcon, DashboardFilledIcon, DocFilledIcon, ManagersIcon } from 'ui/Icons/Icons';
import styles from './AdminNavigation.module.scss';

export function AdminNavigation({ failedExchangesNumber }: { failedExchangesNumber: number }) {
    const [t] = useTranslation();
    const { pathname } = useLocation();

    return (
        <nav className={styles.Nav}>
            <ul className={styles.NavList}>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root:
                                pathname === URLS_MAP.admin.dashboard
                                    ? cn(styles.NavLink, styles.NavLinkActive)
                                    : styles.NavLink
                        }}
                        to={URLS_MAP.admin.dashboard}
                        end
                        iconStart={<DashboardFilledIcon />}
                    >
                        {t('admin.dashboard.title')}
                    </Button>
                </li>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root:
                                pathname === URLS_MAP.admin.transactions
                                    ? cn(styles.NavLink, styles.NavLinkActive)
                                    : styles.NavLink
                        }}
                        to={URLS_MAP.admin.transactions}
                        iconStart={<DocFilledIcon />}
                        iconEnd={
                            failedExchangesNumber ? (
                                <span className={styles.NavLinkBadge}>{failedExchangesNumber}</span>
                            ) : undefined
                        }
                    >
                        {t('admin.transactions.title')}
                    </Button>
                </li>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root:
                                pathname === URLS_MAP.admin.managers
                                    ? cn(styles.NavLink, styles.NavLinkActive)
                                    : styles.NavLink
                        }}
                        to={URLS_MAP.admin.managers}
                        iconStart={<ManagersIcon />}
                    >
                        {t('admin.managers.title')}
                    </Button>
                </li>
                <li className={styles.NavItem}>
                    <Button
                        as={NavLink}
                        isDark
                        classes={{
                            root:
                                pathname === URLS_MAP.admin.logs
                                    ? cn(styles.NavLink, styles.NavLinkActive)
                                    : styles.NavLink
                        }}
                        to={URLS_MAP.admin.logs}
                        iconStart={<DocFilledIcon />}
                    >
                        {t('admin.logs.title')}
                    </Button>
                </li>
            </ul>
        </nav>
    );
}

export function NavigationMobile() {
    const [t] = useTranslation();
    const { pathname } = useLocation();

    return (
        <div className={cn(styles.Bar)}>
            <nav className={cn(styles.BarNav)}>
                <ul className={cn(styles.BarNavList)}>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.dashboard
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.dashboard}
                            end
                            fullWidth
                            iconStart={<DashboardFilledIcon />}
                        >
                            {t('dashboard.title')}
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.transactions
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.transactions}
                            end
                            fullWidth
                            iconStart={<DocFilledIcon />}
                        >
                            {t('transactions.title')}
                        </Button>
                    </li>
                    <li className={cn(styles.BarNavItem)}>
                        <Button
                            as={NavLink}
                            isDark
                            classes={{
                                root:
                                    pathname === URLS_MAP.list
                                        ? cn(styles.BarNavLink, styles.BarNavLinkActive)
                                        : cn(styles.BarNavLink)
                            }}
                            to={URLS_MAP.list}
                            end
                            fullWidth
                            iconStart={<CrownFilledIcon />}
                        >
                            {t('adminsList.title')}
                        </Button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
