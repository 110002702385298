export default {
    title: 'История транзакций',
    searchPlaceholder: 'Поиск',
    download: 'Скачать отчёт',
    filters: {
        title: 'Фильтры',
        paymentTime: 'Время оплаты',
        createdTime: 'Время создания',
        finishedTime: 'Время завершения',
        selectDate: 'Выберите дату'
    },
    stub: {
        title: 'Транзакций не обнаружено',
        description: 'Вы еще не совершили ни одной транзакции'
    },
    tableHeader: {
        number: 'Номер',
        type: 'Тип заявки',
        status: 'Статус',
        date: 'Дата и время',
        paid: 'Отдали',
        received: 'Получили',
        account: 'Аккаунт',
        dateCreated: 'Дата и время создания',
        datePaid: 'Дата и время оплаты',
        dateFinish: 'Дата и время завершения',
        manager: 'Менеджер',
        point: 'Точка'
    },
    type: {
        CRYPTO_FIAT: 'Выдача средств',
        FIAT_CRYPTO: 'Получение средств'
    },
    status: {
        CANCELLED: 'Отменена',
        CREATED: 'Обработка',
        EXPIRED: 'Время вышло',
        FAILED: 'Ошибка',
        MONEY_RECEIVED: 'Оплата получена',
        PAID: 'Клиент оплатил',
        REFUNDED: 'Возврат',
        SENDING: 'Обработка',
        SENT: 'Отправлено',
        SUCCESS: 'Завершена'
    },
    sortType: {
        NUMBER_DESC: 'Номер от новых к старым',
        NUMBER_ASC: 'Номер от старых к новым'
    }
};
