import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { URLS_MAP } from 'routes/routes';
import Button from 'ui/Button/Button';
import Link from 'ui/Link/Link';
import { ArrowDownFilledIcon, ArrowUpFilledIcon, ExitIcon, PointIcon, ProfileFilledIcon } from 'ui/Icons/Icons';
import styles from './ManagerNavigation.module.scss';
import useMediaQuery from '../../hooks/useMediaQuery/useMediaQuery';

interface ManagerNavigationLogOutProps {
    logout: (open: boolean) => void;
    address: string;
    email?: string | null;
}

const shortenedString = (value?: string | null, number = 20) => {
    if (!value) return '-';

    if (value.length < number) return value;

    return `${value.slice(0, number)}...`;
};

export function ManagerNavigation() {
    const [t] = useTranslation();

    return (
        <nav>
            <ul className={styles.Menu}>
                <li className={styles.Card}>
                    <Link to={URLS_MAP.manager.withdrawal}>
                        <div className={cn(styles.CardIcon, styles.CardIconGreen)}>
                            <ArrowUpFilledIcon />
                        </div>
                        <p className={styles.CardName}>{t('managerPage.withdrawal.title')}</p>
                        <p className={styles.CardDescription}>{t('managerPage.withdrawal.description')}</p>
                    </Link>
                </li>
                <li className={styles.Card}>
                    <Link to={URLS_MAP.manager.deposit}>
                        <div className={cn(styles.CardIcon, styles.CardIconPurple)}>
                            <ArrowDownFilledIcon />
                        </div>
                        <p className={styles.CardName}>{t('managerPage.deposit.title')}</p>
                        <p className={styles.CardDescription}>{t('managerPage.deposit.description')}</p>
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export function ManagerNavigationLogOut({ logout, address, email }: ManagerNavigationLogOutProps) {
    const [t] = useTranslation();

    return (
        <div className={styles.Card}>
            <div className={styles.AccountItem}>
                <div className={styles.AccountIcon}>
                    <PointIcon />
                </div>
                <div>
                    <p className={styles.AccountTitle}>{t('managerPage.point')}</p>
                    <p className={styles.AccountDescription}>{address}</p>
                </div>
            </div>
            <div className={styles.AccountDivider} />
            <div className={cn(styles.AccountItem, styles.AccountLogout)}>
                <div className={styles.AccountIcon}>
                    <ProfileFilledIcon />
                </div>
                <div>
                    <p className={styles.AccountTitle}>{t('managerPage.account')}</p>
                    <p className={styles.AccountDescription}>{shortenedString(email)}</p>
                </div>
                <div className={styles.AccountLogoutButton}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        isDark
                        onClick={() => logout(true)}
                        classes={{ root: styles.LogoutButton }}
                        iconStart={<ExitIcon />}
                    />
                </div>
            </div>
        </div>
    );
}

export function ManagerMobileInfo({ logout, address, email }: ManagerNavigationLogOutProps) {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const stringLength = isMobileOrLess ? 25 : 35;

    return (
        <div className={styles.ManagerMobileHeaderRoot}>
            <div className={styles.ManagerMobileHeaderItem}>
                <div className={styles.AccountIcon}>
                    <PointIcon />
                </div>
                <div className={styles.StringSlicingContainer}>
                    <p className={styles.AccountTitle}>{t('managerPage.point')}</p>
                    <p className={styles.AccountDescription}>{shortenedString(address, stringLength)}</p>
                </div>
            </div>
            {isMobileOrLess && <div className={styles.AccountDivider} />}
            <div className={cn(styles.ManagerMobileHeaderItemLeft, styles.ManagerMobileHeaderItem)}>
                <div className={styles.ManagerMobileHeaderInfo}>
                    <div className={styles.AccountIcon}>
                        <ProfileFilledIcon />
                    </div>
                    <div>
                        <p className={styles.AccountTitle}>{t('managerPage.account')}</p>
                        <p className={styles.AccountDescription}>{shortenedString(email, stringLength)}</p>
                    </div>
                </div>
                <div className={styles.AccountLogoutButton}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        isDark
                        onClick={() => logout(true)}
                        classes={{ root: styles.LogoutButton }}
                        iconStart={<ExitIcon />}
                    />
                </div>
            </div>
        </div>
    );
}

export function ManagerNavigationMobile() {
    return (
        <div className={styles.Bar}>
            <nav className={styles.BarNav}>
                <ul className={styles.ManagerMobile}>
                    <li>
                        <Link to={URLS_MAP.manager.withdrawal} className={styles.ManagerMobileItem}>
                            <div className={cn(styles.ManagerMobileContainer, styles.CardIconGreen)}>
                                <ArrowUpFilledIcon className={styles.ManagerMobileIcon} />
                            </div>
                            <div>
                                <p className={styles.ManagerMobileTitle}>Выдача</p>
                                <p className={styles.ManagerMobileDescription}>Наличных средств</p>
                            </div>
                        </Link>
                    </li>
                    <li className={styles.ManagerMobileRightItem}>
                        <Link to={URLS_MAP.manager.deposit} className={styles.ManagerMobileItem}>
                            <div className={cn(styles.ManagerMobileContainer, styles.CardIconPurple)}>
                                <ArrowDownFilledIcon className={styles.ManagerMobileIcon} />
                            </div>
                            <div>
                                <p className={styles.ManagerMobileTitle}>Получение</p>
                                <p className={styles.ManagerMobileDescription}>Наличных средств</p>
                            </div>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
