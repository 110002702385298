import React from 'react';
import { toast, ToastContainer as TContainer, ToastContainerProps, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import insecureRandom from 'utils/insecureRandom/insecureRandom';
import { ReactComponent as SuccessIcon } from 'assets/images/status-success.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/status-error.svg';
import { CrossIcon } from '../Icons/Icons';
import Button from '../Button/Button';
import Alert from '../Alert/Alert';
import { ToastId, CloseButtonProps, ToastOptions, ToastTransition, ToastType, ContainerProps } from './Toast.d';
import './Toast.scss';

const DRAG_ANIMATION_DURATION = 500;

const Transition = {
    slide: { transition: Slide },
    zoom: { transition: Zoom },
    flip: { transition: Flip },
    bounce: { transition: Bounce }
} as const;

export function CloseButton({ closeToast }: CloseButtonProps) {
    return (
        <Button
            variant="text"
            color="gray"
            classes={{ root: 'Toastify__close-button' }}
            iconStart={<CrossIcon />}
            size="small"
            isRound
            onClick={closeToast}
        />
    );
}

function Icon(type: ToastType, className?: string) {
    switch (type) {
        case 'success':
            return <SuccessIcon className={className} />;
        case 'error':
            return <ErrorIcon className={className} />;
        default:
            return undefined;
    }
}

export function Container({ type, content }: ContainerProps) {
    return (
        <Alert
            classes={{
                root: 'Toastify__alert'
            }}
            type={type}
            icon={Icon(type)}
        >
            <div>{content}</div>
        </Alert>
    );
}

export function useToast() {
    const toastId = React.useRef<ToastId>('');
    const timerRef = React.useRef<NodeJS.Timeout>();

    const defaultOptions: ToastOptions = {
        icon: false,
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true
    };

    const notify = (
        content: React.ReactNode,
        options?: ToastOptions,
        type: ToastType = 'info',
        transition: ToastTransition = 'bounce'
    ) => {
        const { type: optType, closeButton } = options ?? {};
        const currentType = optType ?? type;

        const currentOptions = {
            ...defaultOptions,
            ...options,
            closeButton: closeButton ? CloseButton : false,
            closeOnClick: !closeButton
        };

        toastId.current = options?.toastId ?? `toast-${insecureRandom(1, 1000)}`;

        if (!toast.isActive(toastId.current)) {
            clearTimeout(timerRef.current);

            toastId.current = toast[currentType](<Container type={currentType} content={content} />, {
                ...currentOptions,
                toastId: toastId.current,
                ...Transition[transition]
            });
        } else {
            toast.update(toastId.current, {
                ...currentOptions,
                render: <Container type={currentType} content={content} />,
                ...Transition[transition],
                className: `${currentOptions.className ?? ''} DragAnimation`,
                style: {
                    ...currentOptions.style,
                    animationDuration: `${DRAG_ANIMATION_DURATION}ms`
                }
            });

            timerRef.current = setTimeout(
                (id: ToastId) => {
                    toast.update(id, {
                        ...currentOptions,
                        render: <Container type={currentType} content={content} />,
                        ...Transition[transition],
                        className: currentOptions.className ?? '',
                        style: currentOptions.style
                    });
                },
                DRAG_ANIMATION_DURATION,
                toastId.current
            );
        }
    };

    React.useEffect(() => () => clearTimeout(timerRef.current), []);

    return {
        default: (content: React.ReactNode, options?: ToastOptions, transition?: ToastTransition) =>
            notify(content, options, undefined, transition),
        info: (content: React.ReactNode, options?: ToastOptions, transition?: ToastTransition) =>
            notify(content, options, 'info', transition),
        warning: (content: React.ReactNode, options?: ToastOptions, transition?: ToastTransition) =>
            notify(content, options, 'warning', transition),
        error: (content: React.ReactNode, options?: ToastOptions, transition?: ToastTransition) =>
            notify(content, options, 'error', transition),
        success: (content: React.ReactNode, options?: ToastOptions, transition?: ToastTransition) =>
            notify(content, options, 'success', transition)
    };
}

export default function ToastContainer(props: ToastContainerProps) {
    return (
        <TContainer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}
