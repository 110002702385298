import { officeVar, LOCAL_STORAGE_KEY_OFFICE } from 'apollo/cache';

const setOffice = (str: string) => {
    officeVar(str);

    if (str) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY_OFFICE, str);
    }
};

export default setOffice;
