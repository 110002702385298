export default {
    withdrawal: {
        title: 'Выдача наличных средств',
        shortTitle: 'Выдача',
        description: 'Клиент продает криптовалюту'
    },
    deposit: {
        title: 'Получение наличных средств',
        shortTitle: 'Получение',
        description: 'Клиент покупает криптовалюту'
    },
    transactions: 'Транзакции',
    point: 'Точка',
    account: 'Аккаунт'
};
