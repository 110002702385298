import React from 'react';
import { useNavigate } from 'react-router-dom';
import { URLS_MAP } from 'routes/routes';
import { DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import styles from './PaymentCancellation.module.scss';

export default function PaymentCancellation({ onClose }: { onClose: () => void }) {
    const navigate = useNavigate();

    const handleStayOnPageClick = () => onClose();

    // const handleSuspendRequestClick = () => {
    //     console.log('handleSuspendRequestClick');
    // };

    const handleCancelRequestClick = () => {
        navigate({ pathname: URLS_MAP.manager.index });
        onClose();
    };

    return (
        <>
            <DialogHeader
                focusOnClose
                title="Подтвеждение"
                description="Вы уверены что хотите остановить процесс оформления заявки. Позже вы сможете вернуться к данной заявке"
            />
            <DialogFooter>
                <div className={styles.ButtonContainer}>
                    <Button
                        fullWidth
                        size="large"
                        type="button"
                        onClick={handleStayOnPageClick}
                        classes={{ root: styles.Button }}
                    >
                        Остаться на странице
                    </Button>
                    {/* <Button */}
                    {/*    fullWidth */}
                    {/*    size="large" */}
                    {/*    type="button" */}
                    {/*    color="secondary" */}
                    {/*    onClick={handleSuspendRequestClick} */}
                    {/*    classes={{ root: styles.Button }} */}
                    {/* > */}
                    {/*    Приостановить заявку */}
                    {/* </Button> */}
                    <Button
                        fullWidth
                        size="large"
                        type="button"
                        variant="text"
                        color="secondary"
                        onClick={handleCancelRequestClick}
                    >
                        Отменить заявку
                    </Button>
                </div>
            </DialogFooter>
        </>
    );
}
