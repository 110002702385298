export default {
    actions: {
        ACCEPT_CASH: 'Получение наличных',
        CHANGE_PASSWORD: 'Изменение пароля',
        CONFIRM_RESET_PASSWORD: 'Подтверждение изменения пароля',
        CREATE_OFFICE: 'Создание точки',
        CREATE_USER: 'Создание пользователя',
        DELETE_OFFICE: 'Удаление точки',
        DELETE_USER: 'Удаление пользователя',
        DOWNLOAD_REPORT: 'Просмотр отчёта',
        RESET_PASSWORD: 'Сброс пароля',
        SET_FEE: 'Изменение комиссии',
        SIGN_IN: 'Вход',
        UPDATE_OFFICE: 'Редактирование точки',
        UPDATE_USER: 'Редактирование пользователя'
    }
};
