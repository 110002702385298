import React from 'react';

/**
 * Ensures that the input element's previous valid value is retained if the new value is not valid.
 *
 * @param {string} value Next input value.
 * @param {boolean} valid Is true when the field passes validation.
 * @param {string} currentValue Previous input value.
 *
 * @returns {string}
 */
export default (
    {
        target: {
            value,
            validity: { valid }
        }
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentValue: string
) => (value && !valid ? currentValue : value);
